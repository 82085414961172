import axios from 'axios';   

const state={
    expenses:[],  
    expensesReport:[],
    budget:{}
}

const  getters={
    expenses:(state)=>state.expenses,  
    expensesReport:(state)=>state.expensesReport,  
    budget:(state)=>state.budget,  
}

const mutations= {
    setExpenses:(state,expenses)=>state.expenses=expenses,  
    setExpensesReport:(state,expensesReport)=>state.expensesReport=expensesReport,  
    setBudget:(state,budget)=>state.budget=budget,  
} 
const actions={ 
    f_expenses({commit}, params){
        commit('setExpenses',[])
        commit('setExpensesReport',[])
        axios.post('a/ex/search',params)
        .then(res => { 
            commit('setExpenses',res.data.expenses)
            commit('setExpensesReport',res.data.report)
        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_budget({commit}){
        axios.get('a/accounting/budget')
        .then(res => {  
            commit('setBudget',res.data) 
            console.log(res.data);
        })
        .catch(err => {
            console.error(err); 
        })
    },

}

export default {
    getters,
    state,
    actions,
    mutations
}