<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.name"
            required
            label="الاسم"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.address"
            required
            label="العنوان"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.start_date"
            label="تاريخ البدء"
            type="date"
          ></v-text-field>
        </v-col>
         <!-- <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.end_date"
            label="تاريخ الانتهاء"
            type="date"
          ></v-text-field>
        </v-col> -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.profit"
            label="نسبة الفائدة"
            type="number"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.quantity"
            type="number"
            label="المساحة m2"
          ></v-text-field>
        </v-col>
        <v-col dir="ltr" cols="12" sm="6" md="4">
          <v-autocomplete
            outlined
            auto-select-first
            reverse
            v-model="project.project_type_id"
            :items="projectType"
            :rules="[(v) => !!v || 'ادخل نوع المشروع ']"
            required
            item-text="name"
            item-value="id"
            label="نوع المشروع"
          ></v-autocomplete>
        </v-col>
        <v-col dir="ltr" cols="12" sm="6" md="4">
          <v-autocomplete
            outlined
            auto-select-first
            reverse
            multiple
            v-model="project.engineer_id"
            :items="engineers_n" 
            required
            item-text="name"
            item-value="id"
            label="مهندس المشروع"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.expense_limit"
            type="number"
            label="مبلغ الاعتماد"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.project_duration"
            type="number"
            label="مدة المشروع *ايام"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.estimated_cost"
            type="number"
            label="التکلفة التخمینیة"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.project_number"
            type="number"
            label="رقم المشروع"
          ></v-text-field>
        </v-col>
         
        <v-col cols="12">
          <v-text-field
            reverse
            outlined
            v-model="project.notes"
            required
            label="الملاحظات"
          ></v-text-field>
        </v-col>

        <v-alert class="mx-2 my-2" type="error" v-if="inputError">
          خطأ في بعض المدخلات
        </v-alert>
      </v-row>
    </v-container>
    <v-btn @click="next()" class="text-end" color="success">التالي</v-btn>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions,mapGetters } from "vuex";
export default {
  name: "addProjectInfo",
  props: ["customer_id"],
  data() {
    return {
      inputError: false,
      project: {
        name: "",
        address: "",
        start_date: "",
        profit: "",
        quantity: "",
        project_type_id: 1,
        notes: "",
        end_date:'',
        engineer_id:'',
        expense_limit:'',
        project_duration:'',
        estimated_cost:'',
        project_number:''

      },
      projectType: [
        { id: 3, name: "اشراف" },
        { id: 2, name: "تنفيذ" },
        { id: 1, name: "تصميم" },
      ],
    };
  },
  mounted() {
    this.f_engineers_n()
  },
  computed:mapGetters(['engineers_n']),
  methods: {
    ...mapActions(['f_engineers_n']),
    next() {
      this.project.customer_id = this.customer_id; 
      axios
        .post("a/project/add", this.project)
        .then((res) => { 
          this.$emit("next", res.data);
        })
        .catch((err) => {
          console.error(err);
          this.inputError = true;
        });
    },
  },
};
</script>
  