<template>
<div>
    <v-container v-if="companyReport.company_export_report" class="text-center my-5">
        
        <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
            <v-card>
                <v-container class="text-center mp-5">
                    <table id="customers">
                        <tr>
                            <th>الاسم</th>
                            <th>المبلغ</th>
                        </tr>
                        <tr v-for="( item , index ) in detials" :key="index">
                            <td>{{ item.name }} </td>
                            <td>{{ item.amount }} </td>
                        </tr>
                    </table>
                </v-container>
                <v-btn @click="dialog=!dialog" class="mx-5 my-5" color="red">رجوع</v-btn>
            </v-card>
            
        </v-dialog>
        <v-row>
            <v-col  >
                <line-chart   :height="200" :myData="companyReport" />
            </v-col>
        </v-row>
        
        <hr class="my-5">
        <v-row>
            <v-col>
                <table id="customers">
                    <tr>
                        <th>الشهر</th>
                        <th>الوارد</th>
                        <th>المصروف</th>
                        <th>الارباح</th>
                    </tr>
                    <tr v-for="( item , index ) in companyReport.company_export_report.labels" :key="index">
                        <td>{{ item }}</td>
                        <td>
                            <div> {{ companyReport.import_company_report.values[index] }} </div>
                            <v-btn small class="my-2 " @click="openDetailsImport(item)">
                                <v-icon color="blue">mdi-open-in-new</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <div>{{ companyReport.company_export_report.values[index] }} </div>
                            <v-btn small class="my-2 " @click="openDetails(item)">
                                <v-icon color="blue">mdi-open-in-new</v-icon>
                            </v-btn>
                        </td>
                        <td dir="ltr">{{ companyReport.total_profit_report.values[index] }}
                        </td>
                    </tr>
                </table>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>

        <v-row   >
                <v-col cols="2">
                    <v-text-field  reverse outlined v-model="years" label="عدد السنوات" type="number"></v-text-field>
                </v-col> 
                    <v-btn class="mt-3" color="green" @click="getCompanyReport(years)">
                        عرض البيانات
                    </v-btn> 
        </v-row>
    </v-container>
</div>
</template>

<script>
import axios from 'axios';
import {
    mapActions,
    mapGetters,
    mapMutations, 
} from 'vuex';
// import BarChart from './barChart.vue';
import LineChart from './lineChart.vue';
export default {
    name: "mainReport",
    data() {
        return {
            detials: '',
            dialog: false,
            years:1
        }
    },
    components: {
        // BarChart,
        LineChart
    },
    computed: mapGetters(['companyReport']),
    methods: {
        ...mapActions(['f_companyReport']),
        ...mapMutations(['setCompanyReport']),

        openDetails(month) {
            axios.post('a/report/company_report_month', {
                    date: month
                })
                .then(res => {
                    console.log(res)
                    this.detials = res.data.expenses_company_month
                    this.dialog = true
                })
                .catch(err => {
                    console.error(err);
                })
        },
        openDetailsImport(month) {
            axios.post('a/report/company_import_month', {
                    date: month
                })
                .then(res => {
                    console.log(res)
                    this.detials = res.data.company_import_month
                    this.dialog = true
                })
                .catch(err => {
                    console.error(err);
                })
        },
        getCompanyReport(){
            this.setCompanyReport([])
            this.f_companyReport(this.years)

        }

    }, 
    created(){
        this.setCompanyReport('')
    }
};
</script>

<style scoped>
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #04AA6D;
    color: white;
}
</style>
