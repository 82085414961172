<template>
    <v-container  fluid> 
    <v-data-table   
    :headers="headers"
    :search="search"  
    :items="projectExpenses"    
     class="my_btn bg-main"
    :footer-props="{
          'items-per-page-text':'عدد العناصر',
            'next-icon':'$prev',
            'prev-icon':'$next', 
          }"
  >
    <template  v-slot:top>
      <v-toolbar
        flat  class=" bg-main"
      >
      <!-- header -->
       المقبوضات
       <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>  
         <v-spacer></v-spacer>
        <!-- add and delete dialog -->
        <v-dialog 
          v-model="dialog"
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              reverse 
              color="blue"
              dark
              class="mb-2 my_btn elevation-0"
              v-bind="attrs"
              v-on="on"
              @click="checkPermissions('expense-add')"
            >أضافة<v-icon>mdi-plus</v-icon>  
            </v-btn> 
            <!-- print btn -->
            
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <!-- dialog add and edit  -->
            <v-card-text>
              <v-container id="test">
                <v-row >
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field 
                      outlined 
                      v-model="editedItem.title"
                      :rules="rules.required"
                      required
                      label="الفقرة"
                      reverse
                    >   
                    </v-text-field>
                  </v-col> 
                  <v-col dir="ltr" cols="12"  sm="6"  md="4" >
                    <v-autocomplete  
                    reverse
                    outlined
                      v-model="editedItem.person.id" 
                      :items="customers_n" 
                       :rules="[v => !!v || 'ادخل المالك']"
                      required
                      item-text="name"
                      item-value="person_id"
                      label=" المالك" 
                    ></v-autocomplete>
                  </v-col>
                  
                      <v-col cols="12"  sm="6"  md="4" >
                        <v-text-field  outlined reverse v-model="formattedCurrencyValue" label="المبلغ" type="numper" @blur="focusOut" @focus="focusIn"></v-text-field>

                      </v-col>
                      <v-col dir="ltr" cols="12" sm="6" md="4">
                      <v-autocomplete reverse outlined v-model="editedItem.safe_id" :items="my_safes"
                        :rules="[v => !!v || 'اختر القاصه']" required item-text="name" item-value="id" label="القاصة ">
                      </v-autocomplete>
                    </v-col>
                      <v-col cols="12"  sm="6"  md="4" >
                        <v-text-field
                          reverse
                          outlined
                          v-model="editedItem.invoice_date"
                          label="تاريخ الدفع" 
                          type="date"
                        ></v-text-field>
                      </v-col>
                </v-row>  
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" large text @click="close" > إلغاء </v-btn>
              <v-btn color="green white--text" large  
                :disabled="!formIsValid"  type="submit"  @click="save" > حفظ </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog  v-model="dialogDelete" max-width="500px">
          <v-card>
            <!-- delete dialog -->
            <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
              <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    > 
    <!-- time -->
    <template v-slot:[`item.first_invoice_date`]="{item}">
          <div></div>
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"
                  v-if="item.first_invoice_date"
                  >{{item.first_invoice_date[0]}}</span>
              </template>
              <span v-if="item.first_invoice_date">{{item.first_invoice_date[1].slice(0,10)}}</span>
          </v-tooltip>
      </template>
    
    <!-- actions of table -->
    <template  v-slot:[`item.operation`]="{item}" >
      <!-- <v-icon color="" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon> -->
      <v-icon color="red" @click="deleteItem(item)" > mdi-delete </v-icon>  
      <v-icon color="blue" @click="printExpence(item)"> mdi-printer </v-icon>
    </template>
    <template v-slot:no-data>
      لا توجد بيانات
    </template>
  </v-data-table>

        <v-row  class="my-5 text-center">
            <v-col cols="12" class="elevation-0">
            <v-alert type="success" class="my_btn" border="right">
                المبلغ الكلي : {{projectExpensesReport.total_import_sum}}
            </v-alert>
            </v-col>
        </v-row> 
       <v-dialog v-model="dialogPrintExpence">
       <v-btn @click="dialogPrintExpence=!dialogPrintExpence" large color="error">اغلاق</v-btn>

      <div v-if="itemToPrint" class="p_right" id="p_expence">
        <h3 class="p_center">وصل قبض</h3>
        <h4> رقم الوصل :<b>{{bill_number}}</b></h4>

        <h4>
          
           استلمت من السيد <div v-if="itemToPrint.person" class="p_box">{{itemToPrint.person.name}}</div><br>
           مبلغا وقدره  <div class="p_box">{{itemToPrint.total}}</div><br>
           وذلك عن <div  class="p_box">{{itemToPrint.title}}</div> <br>
            المبلغ كتابتا <div  class="p_box">-</div> <br>
          <br>
        </h4>

          <div class="p_left" >
            <hr>
            <div style="display: flex; justify-content: space-around;">
            <div>
              <h3>توقيع المستلم</h3>  
            </div>
            <div>
              <h3>توقيع المسلم</h3>  
            </div>
          </div>
      </div>
      </div> 
    </v-dialog>
    </v-container>
</template>
<script>  
import tools from '@/plugins/tools'
import axios from 'axios'
import { mapActions,mapMutations,mapGetters } from 'vuex'
import printing from '../../plugins/print'
export default {  
    name:'projectImport',
    props:['id'],
    data(){
        return{
            dialog: false,
            dialogDelete: false, 
            dialogPrintSet:false,
            dialogPrint:false,
            dialogSort:false,
            dialogPrintExpence:false,
            itemToPrint:'',
            search:'', 
            rules: { 
                required: [val => (val || '').length > 0 || 'ادخل الحقل '],
                },
            headers: [
                { text: 'الفقرة',value: 'title',align:'start',},   
                { text: 'تاريخ ', value: 'first_invoice_date' }, 
                { text: 'المبلغ  ', value: 'total' }, 
                { text: 'من  ', value: 'person.name' },  
                { text: 'العمليات', value: 'operation', sortable: false}, 
            ],
             headersPrint: [ 
                { text: 'الفقرة',value: 'title',align:'start',sortable: false },   
                { text: 'تاريخ', value: 'first_invoice_date',sortable: false  }, 
                { text: 'من  ', value: 'person.name',sortable: false },  
                { text: 'المبلغ  ', value: 'total' ,sortable: false }, 
 
            ],  
            
        currencyValue: 0,
        formattedCurrencyValue: "0",
        write_total: '',

            editedIndex: -1,
            editedItem: {
                title: '', 
                person:{id:''},
            },
            defaultItem: {
                title: '', 
                person:{id:''},
               
            },
        }
    },
    computed:{
     ...mapGetters(['my_safes','projectExpenses','projectExpensesReport' ,'customers_n']),
     formTitle () {
        return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
      },
      formIsValid () {
        return (
          Boolean(this.editedItem.title ) &&  
          Boolean(this.write_total ) && 
          Boolean(this.editedItem.invoice_date ) && 
          Boolean(this.editedItem.person.id )  &&
          Boolean(this.editedItem.safe_id)
        )
        }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created(){
      tools.checkPermissions('expense-search')
      this.setProjectExpenses([])
    },

    mounted () {
          let params={} 
          params.project_id=this.id
          params.expense_type_id=7 
          this.f_projectExpenses(params)  
          this.f_customers_n()
          this.f_my_safes()
    }, 
    methods:{
      ...mapMutations(['setProjectExpenses']),
      ...mapActions(['f_my_safes','f_projectExpenses','addExpenses','editExpenses','deleteExpenses' ,'f_customers_n']), 

       sorting(){
        this.dialogSort=true
      },
      // print() {
      //       this.dialogPrintSet = true;
      //   },
        // print() {
        //     this.dialogPrintSet = false;
        //     this.dialogPrint = true;
        //     printing([{text:'p_expence',name:'dd'}])
        //     setTimeout(()=>{

        //       this.dialogPrint = false;
        //     },200)
        // },

        
        focusOut: function() {
            // Recalculate the currencyValue after ignoring "$" and "," in user input
            this.write_total = parseFloat(this.formattedCurrencyValue.replace(/[^\d]/g, ""))
            // Ensure that it is not NaN. If so, initialize it to zero.
            // This happens if user provides a blank input or non-numeric input like "abc"
            if (isNaN(this.write_total)) {
                this.write_total = 0
            }
						// Format display value based on calculated currencyValue
            this.formattedCurrencyValue = this.write_total.toLocaleString('en-US')
        },
        focusIn: function() {
            // Unformat display value before user starts modifying it
            this.formattedCurrencyValue = this.write_total.toString()
        },


      printExpence(item){
        axios.get('a/invoice/number/add/'+item.id)
                .then((res) => {
                  this.bill_number=res.data.bill_number 
                  this.itemToPrint=item 
                  this.dialogPrintExpence=true;
                  printing([{text:'p_expence',name:'dd'}])
                 setTimeout(()=>{

                  this.dialogPrintExpence=true;

                },200)

                 })
                .catch(err => { 
                    console.error(err);
                })
                
          
        },

        checkPermissions(per){tools.checkPermissions(per)},

      editItem (item) {
        this.checkPermissions('expense-update')
        this.editedIndex = this.projectExpenses.indexOf(item)
        this.editedItem = Object.assign({}, item)


        this.editedItem.project_id=this.id

        this.dialog = true
      },

      deleteItem (item) {
        this.checkPermissions('expense-delete')
        this.editedIndex = this.projectExpenses.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        let params={}
        params.expense_type_id=7
        params.project_id=this.id
        params.toProject=true
        
        this.editedItem.fetch=params

        this.deleteExpenses(this.editedItem)
        this.closeDelete()  
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }, 
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
        
      save () {//save dialog add and edit 
              this.editedItem.person_id=this.editedItem.person.id
              this.editedItem.expense_type_id=7
              this.editedItem.is_cash=1
              this.editedItem.is_complete=1
              this.editedItem.project_id=this.id
              
              let params={}
              params.expense_type_id=7
              params.project_id=this.id
              params.toProject=true
              
              this.editedItem.fetch=params
              this.editedItem.total=this.write_total
              
      
              //add new item  
              this.addExpenses(this.editedItem) 
        this.close()
      },
 
    },

}
</script>