<template>
  <v-container   fluid>  
  <template> 
<!-- main table -->
  <v-data-table    
    :headers="headers"
    :search="search"  
    :items="guessProject.measurements"    
     class="my_btn bg-main"
    :footer-props="{
          'items-per-page-text':'عدد العناصر',
            'next-icon':'$prev',
            'prev-icon':'$next', 
          }"
  >
    <template  v-slot:top>
      <v-toolbar
        flat  class=" bg-main"
      >
      <!-- header -->
        <v-toolbar-title>فقرات المشروع التخميني</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider> 
        <v-toolbar-title>{{guessProject.name}}</v-toolbar-title>

          <v-spacer></v-spacer>
        <!-- search -->
         <v-text-field 
            reverse
            v-model="search"
            append-icon="mdi-magnify"
            label="بحث"
            single-line
            hide-details 
        >
        </v-text-field>
         <v-spacer></v-spacer>
        <!-- add and delete dialog -->
        <v-dialog 
          v-model="dialog"
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              reverse 
              color="blue"
              dark
              
              class="mb-2 my_btn elevation-0"
              v-bind="attrs"
              v-on="on"
            >أضافة<v-icon>mdi-plus</v-icon>  
            </v-btn> 
            <!-- print btn -->
            <v-btn 
              class="mb-2 mx-2 my_btn elevation-0" 
              color="main" 

              @click="print()"
            >  <v-icon color="blue" >mdi-printer-outline</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <!-- dialog add and edit  -->
            <v-card-text>
              <v-container>
                <v-row >
                  <v-col
                  
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field 
                      outlined 
                      v-model="editedItem.title"
                      :rules="rules.required"
                      required
                      label="الفقرة"
                      reverse
                    >   
                    </v-text-field>
                  </v-col>
                  <v-col dir="ltr" cols="12"  sm="6"  md="4" >
                    <v-autocomplete 
                      reverse
                      outlined
                      v-model="editedItem.unit_id" 
                      :items="units" 
                      :rules="[v => !!v || 'ادخل الوحدة']" 
                      item-text="name"
                      item-value="id"
                      label="الوحدة " 
                    ></v-autocomplete>
                  </v-col>
                     <v-col cols="12"  sm="6"  md="4" >
                      <v-text-field
                        outlined
                        v-model="editedItem.length"
                        label="الطول" 
                        type="number"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12"  sm="6"  md="4" >
                      <v-text-field
                        outlined
                        v-model="editedItem.width"
                        label="العرض" 
                        type="number"
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12"  sm="6"  md="4" >
                      <v-text-field
                        outlined
                        v-model="editedItem.height"
                        label="الارتفاع" 
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  sm="6"  md="4" >
                      <v-text-field
                      reverse
                         outlined
                        v-model="editedItem.unit_price"
                        label="سعر الوحدة" 
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  sm="6"  md="4" >
                      <v-text-field
                      reverse
                        outlined
                        v-model="editedItem.count"
                        label="الكمية" 
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <h3 v-if="editedItem.unit_price">المبلغ الكلي:{{quantity_sumation}} </h3>
                      <v-text-field v-else outlined reverse v-model="quantity" label="المبلغ" type="numper"></v-text-field>
                    </v-col>

                    <!-- <v-col cols="12"  sm="6"  md="4" >
                      <v-text-field

                        outlined
                        reverse
                        v-model="quantity"
                        label="المبلغ" 
                        type="number"
                      ></v-text-field>
                    </v-col> -->
                  </v-row> 
                 
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" large text @click="close" > إلغاء </v-btn>
              <v-btn color="green white--text" large    :disabled="!formIsValid"  type="submit"  @click="save" > حفظ </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog  v-model="dialogDelete" max-width="500px">
          <v-card>
            <!-- delete dialog -->
            <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
              <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    >
   
    <!-- actions of table -->
    <template  v-slot:[`item.operation`]="{item}" >
      <v-icon color="" class="mr-2" @click="editItem(item)" > mdi-pencil </v-icon>
      <v-icon color="red" @click="deleteItem(item)" > mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      لا توجد بيانات
    </template>
  </v-data-table>
  <v-row  class="my-5 text-center">
    <v-col cols="12" md="4"  class="elevation-0">
      <v-alert type="info" class="my_btn" border="right">
        المبلغ الكلي : {{guessProject.quantity_sum}}
      </v-alert>
    </v-col>
  </v-row>
 </template>


 <!-- to print -->
 <v-dialog v-model="dialogPrint">
  <v-btn @click="dialogPrint=!dialogPrint" large color="error">اغلاق</v-btn>

  <h3   id="p_title"  class="p_center">المشروع التخميني{{guessProject.name}}}</h3> 

   <div  >
    <v-data-table 
    id="table" 
    :headers="headersPrint"
    :search="search"
    :items="guessProject.measurements"   
     class="elevation-1" 
     hide-default-footer
     disable-pagination 
    > 
    </v-data-table>
  </div>
  
  <h3   id="p_footer"  class="p_right p_box"> المبلغ الكلي : {{guessProject.quantity_sum}}</h3> 
 </v-dialog>
</v-container>


</template>
<script>  
import printing from '@/plugins/print';
import { mapActions, mapGetters } from 'vuex'  
  export default { 
    name:'guessProject',
    props:['id'],
    data: () => ({
      dialog: false,
      dialogDelete: false, 
      dialogPrint:false,  
      search:'', 
      quantity:'',
      rules: { 
          required: [val => (val || '').length > 0 || 'ادخل الحقل '],
        }, 
      headers: [
        { text: 'الفقرة',value: 'title',align:'start',sortable: false },   
        { text: 'الطول', value: 'length' , groupable: false}, 
        { text: 'العرض', value: 'width' , groupable: false}, 
        { text: 'الارتفاع', value: 'height' , groupable: false}, 
        { text: ' الوحدة', value: 'unit.name' , groupable: false}, 
        { text: 'سعر الوحدة', value: 'unit_price_format' , groupable: false}, 
        { text: 'الكمية', value: 'count' , groupable: false}, 
        { text: 'المبلغ', value: 'quantity_format' , groupable: false},
        { text: ' العمليات', value: 'operation', sortable: false , groupable: false }, 
      ],
      headersPrint: [
        { text: 'الفقرة',value: 'title',align:'start', sortable: false },   
        { text: 'الطول', value: 'length' , sortable: false}, 
        { text: 'العرض', value: 'width' , sortable: false}, 
        { text: 'الارتفاع', value: 'height' , sortable: false}, 
        { text: ' الوحدة', value: 'unit.name' , sortable: false}, 
        { text: 'سعر الوحدة', value: 'unit_price_format' , sortable: false}, 
        { text: 'الكمية', value: 'count' , sortable: false}, 
        { text: 'المبلغ', value: 'quantity_format' , sortable: false},      
      ],  
      editedIndex: -1,
      editedItem: {
        title: '',
        length: '',
        width: '', 
        height:'',  
        count:'', 
        unit_price:'',
      },
      defaultItem: { 
        title: '',
        length: '',
        width: '', 
        height:'',  
        count:'', 
        unit_price:'',
      },  
    }),

    computed: {
      ...mapGetters(['guessProject','units']),
      quantity_sumation(){
        return this.editedItem.count*this.editedItem.unit_price
      },
      // total_sum(){let sum = 0
      //   this.guessProject.measurements.forEach(element => {
      //     if(element.quantity )
      //       sum += element.quantity 
      //   });
      //   return sum
      // },
      formTitle () {
        return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
      },
      formIsValid () {
        return (
          Boolean(this.editedItem.title ) )
        }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created(){
      this.f_guessProject(this.$route.params.id)
      this.f_unit()
    }, 
    methods: { 
      ...mapActions(['f_guessProject','f_unit','addCard','deleteCard','updateCard']),  
      print() {
         this.dialogPrint=true ;
         printing(["p_title","table",'p_footer'])
        }, 
      editItem (item) {
        this.editedIndex = this.guessProject.measurements.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.unit_id=this.editedItem.unit.id
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.guessProject.measurements.indexOf(item)
        this.editedItem.id = item.id
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        let card={}
        card.main_id=this.$route.params.id
        card.id=this.editedItem.id
        card.type='meas'
        this.deleteCard(card)  
       this.dialogDelete = false

      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }, 
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {//save dialog add and edit
             
        if (this.editedIndex > -1) {
          //update item    
            let card={}
            this.editedItem.guess_project_id=this.$route.params.id
            
            if(this.editedItem.unit_price)
            this.editedItem.quantity = this.quantity_sumation
            else
            this.editedItem.quantity = this.quantity;

            card.data=this.editedItem
            card.main_id=this.$route.params.id
            card.id=this.editedItem.id
            card.type='meas'
            this.updateCard(card)
        } else { 
          //add new item  
            let card={}
            this.editedItem.guess_project_id=this.$route.params.id
            
            if(this.editedItem.unit_price)
            this.editedItem.quantity = this.quantity_sumation
            else
            this.editedItem.quantity = this.quantity;       

            card.data=this.editedItem
            card.main_id=this.$route.params.id 
            card.type='meas'
            this.addCard(card)
        }
        this.close()
      },
    },
  }
</script>
