<template>
<v-container fluid>
    <h2 class="text-center my-2">حسابات المكتب</h2>
    <v-dialog v-model="dialogSorting" transition="dialog-transition">
        <v-card>
            <v-card-title primary-title>
                البحث حسب
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field outlined v-model="searchExpenses.title" required label="الفقرة" reverse>
                            </v-text-field>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete small outlined auto-select-first reverse v-model="searchExpenses.is_cash" :items="cash" item-text="name" item-value="id" label="نوع الدفع"></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined chips multiple v-model="searchExpenses.user_ids" :items="users_name" item-text="name" item-value="id" label="مستخدم"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field reverse outlined v-model="searchExpenses.from" label=" من تاريخ" type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field reverse outlined v-model="searchExpenses.to" label="الى تاريخ" type="date"></v-text-field>
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="dialogSorting=!dialogSorting" dark color="red">الغاء</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="searchEx()" color="success">بحث</v-btn> 
            </v-card-actions> 
        </v-card>
    </v-dialog>
    <template>
        <!-- main table -->
        <v-data-table :headers="headers" :search="search" show-expand :items="expenses" show-group-by class="my_btn bg-main" :footer-props="{
        'items-per-page-text':'عدد العناصر',
            'next-icon':'$prev',
            'prev-icon':'$next', 
        }">
            <template v-slot:top>
                <v-toolbar flat class=" bg-main">
                    <!-- header -->
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn color="success" small class="my_btn" @click="dialogSorting=!dialogSorting">
                        <v-icon>mdi-sort-bool-ascending-variant</v-icon> البحث التفصيلي
                    </v-btn>
                    <v-spacer></v-spacer>

                    <!-- search -->
                    <v-text-field reverse v-model="search" append-icon="mdi-magnify" label="بحث" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <!-- add and delete dialog -->
                    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn reverse color="blue" dark class="mb-2 my_btn elevation-0" v-bind="attrs" v-on="on" @click="checkPermissions('expense-add')">أضافة<v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <!-- print btn -->
                            <v-btn class="mb-2 mx-2 my_btn elevation-0" color="main" @click="print()">
                                <v-icon color="blue">mdi-printer-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <!-- delete dialog -->
                            <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
                                <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            >
            <template v-slot:[`group.header`]="{headers, isOpen, toggle ,group,remove}">
                <th class="text-right" :colspan="headers.length">
                    <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{group}}
                    <v-icon @click="remove">mdi-close</v-icon>
                </th>
            </template>
            <!-- more detials -->
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    تم الادخال بواسطة: <b class="mx-1"> {{item.user.name}} </b>
                    التاريخ: <b class="mx-1">{{item.created_at[1].slice(0,10)}}</b>
                    المدفوع: <v-chip class="mx-4" outlined color="green"><b class="mx-1">{{item.paid}}</b></v-chip>
                    المتبقي: <v-chip class="mx-4" outlined color="red"> <b class="mx-1">{{item.rest}}</b></v-chip>
                </td>
            </template>
            <!-- time -->
            <template v-slot:[`item.first_invoice_date`]="{item}">
                <div></div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" v-if="item.first_invoice_date">{{item.first_invoice_date[0]}}</span>
                    </template>
                    <span v-if="item.first_invoice_date">{{item.first_invoice_date[1].slice(0,10)}}</span>
                </v-tooltip>
            </template>
            <!-- type  -->
            <template v-slot:[`item.is_cash`]="{item}">
                <v-chip small color="red" text-color="white" v-if="!item.is_cash">آجل</v-chip>
                <v-chip small color="cyan" text-color="white" v-else>نقد</v-chip>
            </template>
            <!-- actions of table -->
            <template v-slot:[`item.operation`]="{item}">
                <v-icon color="" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
                <v-icon color="blue" @click="openExpense(item.id)"> mdi-open-in-new</v-icon>
            </template>
            <template v-slot:no-data>
                لا توجد بيانات
            </template>
        </v-data-table>
        <v-row class="my-5 text-center">
            <v-col cols="12" md="4" class="elevation-0">
                <v-alert type="error" class="my_btn" border="right">
                    المبلغ الكلي المطلوب: {{expensesReport.rest_sum}}
                </v-alert>
            </v-col>
            <v-col cols="12" md="4" class="elevation-0">
                <v-alert type="success" class="my_btn" border="right">
                    المبلغ الكلي المدفوع: {{expensesReport.paid_sum}}
                </v-alert>
            </v-col>
            <v-col cols="12" md="4" class="elevation-0">
                <v-alert type="info" class="my_btn" border="right">
                    المبلغ الكلي : {{expensesReport.total_export_sum}}
                </v-alert>
            </v-col>
        </v-row>
    </template>

    <!-- to print -->
    <v-dialog v-model="dialogPrint">
        <v-btn @click="dialogPrint=!dialogPrint" large color="error">اغلاق</v-btn>

        <h3 v-if="expenses" id="p_title" class="p_center">حسابات المكتب </h3>

        <div>
            <v-data-table id="table" :headers="headersPrint" :search="search" :items="expenses" class="elevation-1" hide-default-footer disable-pagination>
                <template v-slot:[`item.is_complete`]="{item}">
                    <div v-if="item.is_complete">مكتمل</div>
                    <div v-else>غير مكتمل</div>
                </template>
                <template v-slot:[`item.is_cash`]="{item}">
                    <div v-if="item.is_cash">نقد</div>
                    <div v-else>أجل</div>
                </template>

            </v-data-table>
        </div>
    </v-dialog>
</v-container>
</template>

<script>
 import printing from '@/plugins/print'
 import tools from '@/plugins/tools'
 import router from '@/router'
 import {
     mapActions,
     mapGetters
 } from 'vuex'
 export default {
     name: 'showAccount',
     data: () => ({
         dialog: false,
         dialogDelete: false,
         dialogPrint: false,
         dialogSorting: false,
         search: '',

         cash: [{
             id: 1,
             name: 'نقد'
         }, {
             id: 0,
             name: 'اجل'
         }],
         headers: [{
                 text: 'الفقرة',
                 value: 'title',
                 align: 'start',
                 groupable: false,
             },
             {
                 text: 'الدفع',
                 value: 'is_cash'
             },
             {
                 text: 'التبويب',
                 value: 'expense_type.name'
             },
             {
                 text: 'تاريخ ',
                 value: 'first_invoice_date'
             },
             {
                 text: 'الكلي',
                 value: 'total',
                 groupable: false
             },

             {
                 text: ' العمليات',
                 value: 'operation',
                 sortable: false,
                 groupable: false
             },
         ],
         headersPrint: [{
                 text: 'الفقرة',
                 value: 'title',
                 sortable: false
             },
             // { text: 'المحاسب', value: 'user.name', sortable: false  },
             {
                 text: 'التبويب',
                 value: 'expense_type.name',
                 sortable: false
             },
             {
                 text: 'المبلغ ',
                 value: 'total',
                 sortable: false
             },
             {
                 text: 'المدفوع',
                 value: 'paid',
                 sortable: false
             },
             {
                 text: 'المتبقي',
                 value: 'rest',
                 sortable: false
             },
         ],
         searchExpenses: {
             is_cash: '',
             is_complete: '',
             construction_category_id: '',
             project_id: '',
             expense_type_id: '',
             person_id: '',
             user_id: '',
             from: '',
             to: '',
         },
         searchExpensesDefault: {
             is_cash: '',
             is_complete: '',
             construction_category_id: '',
             project_id: '',
             expense_type_id: '',
             person_id: '',
             user_id: '',
             from: '',
             to: '',
         }
     }),

     computed: {
         ...mapGetters(['my_safes', 'users_name', 'expenses', 'expensesReport', 'expensesType']), 
     },

     watch: {
         dialog(val) {
             val || this.close()
         },
         dialogDelete(val) {
             val || this.closeDelete()
         },
     },

     mounted() {
         tools.checkPermissions('expense-search')
         let params = {}
         params.expense_type_ids = [9, 15];
         this.f_expenses(params)
         this.f_users_name();
         this.f_my_safes()
     },
     methods: {
         ...mapActions(['f_my_safes', 'f_users_name', 'f_expenses', 'addExpenses', 'addExpensesCash', 'editExpenses', 'deleteExpenses', 'f_expensesType']),
         openExpense(id) {
             router.push('/expensesDetials/' + id)
         },
         searchEx() {
             this.searchExpenses.expense_type_ids = [9, 15];
             this.f_expenses(this.searchExpenses)
             this.dialogSorting = false
             this.searchExpenses = Object.assign({}, this.searchExpensesDefault)
         },
         print() {
             this.dialogPrint = true;
             printing(['p_title', "table"])
         },
         checkPermissions(per) {
             tools.checkPermissions(per)
         },

         deleteItem(item) {
             this.checkPermissions('expense-delete')
             this.editedIndex = this.expenses.indexOf(item)
             this.editedItem.id = item.id
             this.dialogDelete = true
         },
         deleteItemConfirm() {
             let params = {}
             params.expense_type_id = 9;
             this.editedItem.fetch = params

             this.deleteExpenses(this.editedItem)
             this.closeDelete()
         },

         closeDelete() {
             this.dialogDelete = false
             this.$nextTick(() => {
                 this.editedItem = Object.assign({}, this.defaultItem)
                 this.editedIndex = -1
             })
         },

     },
 }
</script>
