<template>
  <v-container>
    <h2 class="text-center">بيانات الصرف</h2>
    <v-row class="my-3 elevation-1 ">
      <v-col class="elevation-2 " cols="12" sm="6" md="3">
        <h4> الفقرة: {{expense.title}} </h4>
      </v-col>
      <v-col class="elevation-2 " cols="12" sm="6" md="3">
        <h4>الدفع:
          <v-chip small color="red" text-color="white" v-if="!expense.is_cash">آجل</v-chip>
          <v-chip small color="cyan" text-color="white" v-if="expense.is_cash">نقد</v-chip>
        </h4>
      </v-col>
      <!-- <v-col class="elevation-2 " cols="12" sm="6" md="3">
        <h4> مكتمل :
          <v-chip small color="red" text-color="white" v-if="!expense.is_complete">كلا </v-chip>
          <v-chip small color="cyan" text-color="white" v-if="expense.is_complete">نعم</v-chip>
        </h4>
      </v-col> -->
      <v-col v-if="expense.project" class="elevation-2 " cols="12" sm="6" md="3">
        <h4 > المشروع:
          {{expense.project.name}}
        </h4>
      </v-col>
      <v-col v-if="expense.expense_type" class="elevation-2 " cols="12" sm="6" md="3">
        <h4> التبويب:
          {{expense.expense_type.name}}
        </h4>
      </v-col>
      <v-col v-if="expense.construction_category" class="elevation-2 " cols="12" sm="6" md="3">
        <h4> مرحلة العمل :
          {{expense.construction_category.name}}
        </h4>
      </v-col>
      <v-col v-if="expense.person" class="elevation-2 " cols="12" sm="6" md="3">
        <h4> الاسم :
          {{expense.person.name}}
        </h4>
      </v-col>
      <v-col v-if="expense.user" class="elevation-2 " cols="12" sm="6" md="3">
        <h4> الاضافة بواسطة :
          {{expense.user.name}}
        </h4>
      </v-col>
      <v-col v-if="expense" class="elevation-2 " cols="12" sm="6" md="3">
        <h4> تم الاضافة في :
          {{expense.created_at[1].slice(0,10)}} الوقت: {{expense.created_at[1].slice(11,19)}}

        </h4>
      </v-col> 
    </v-row>
    <v-row class="my-3 elevation-1 ">
      <v-col v-if="expense" class="elevation-2 " cols="12" sm="6" md="3">
        <v-alert border="right" class="white--text text-center" color="indigo">
          <b> المبلغ: </b>{{expense.total}}
        </v-alert>
      </v-col>
      <v-col class="elevation-2 " v-if="expense" cols="12" sm="6" md="3">
        <v-alert border="right" class="white--text text-center" color="green darken-1">
          <b> المدفوع: </b>{{expense.paid}}
        </v-alert>
      </v-col>
      <v-col v-if="expense" class="elevation-2 " cols="12" sm="6" md="3">
        <v-alert border="right" class="white--text text-center" color="pink darken-1">
          <b> المتبقي: </b>{{expense.rest}}
        </v-alert>
      </v-col>
    </v-row>
    
    <v-data-table :headers="headers" :search="search" :items="expense.exported" show-expand class="elevation-1 my-6" 
    :footer-props="{
          'items-per-page-text':'عدد العناصر',
            'next-icon':'$prev',
            'prev-icon':'$next', 
          }"
        >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- header -->
          <v-toolbar-title class="green--text"> السلف المدفوعة</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- add and delete dialog -->
          <v-dialog v-model="dialog" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">أضافة<v-icon>mdi-plus</v-icon>
              </v-btn>
              <!-- print btn -->
              <v-btn class="mb-2 mx-2  elevation-2" @click="print()"> طباعة السلفة <v-icon color="blue">
                  mdi-printer-outline</v-icon>
              </v-btn>

            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <!-- dialog add and edit  -->
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete outlined reverse v-model="editedItem.is_exported" :items="exported"
                        :rules="[v => (!!v || v==0 )|| 'ادخل الدفع']" required item-text="name" item-value="id"
                        label="النوع "></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field outlined reverse v-model="editedItem.title" label="الفقرة"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field reverse :rules="rules.required" outlined v-model="editedItem.invoice_date"
                        label="التاريخ " type="date"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete reverse outlined v-model="editedItem.unit_id" :items="units"
                        :rules="[v => !!v || 'ادخل الوحدة']" required item-text="name" item-value="id" label="الوحدة ">
                      </v-autocomplete>
                    </v-col>
                    <v-checkbox label="اضافة ذرعة" v-model="is_magr"></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field outlined reverse v-model="editedItem.notes" label="الملاحضات"></v-text-field>
                    </v-col>
                    <v-col v-if="is_magr" cols="12" sm="6" md="4">
                      <v-text-field outlined v-model="editedItem.length" label="الطول" type="numper"></v-text-field>
                    </v-col>
                    <v-col v-if="is_magr" cols="12" sm="6" md="4">
                      <v-text-field outlined v-model="editedItem.width" label="العرض" type="numper"></v-text-field>
                    </v-col>
                    <v-col v-if="is_magr" cols="12" sm="6" md="4">
                      <v-text-field outlined v-model="editedItem.height" label="الارتفاع" type="numper"></v-text-field>
                    </v-col>
                    <v-col v-if="is_magr" cols="12" sm="6" md="4">
                      <v-text-field outlined v-model="editedItem.completion_rate" label="نسبة الانجاز %" type="numper">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field reverse outlined :rules="rules.required" v-model="editedItem.unit_price"
                        label="سعر الوحدة" type="numper"></v-text-field>
                    </v-col>
                    <v-col v-if="is_magr" cols="12" sm="6" md="4">
                      <v-text-field :rules="rules.required" type="number" reverse outlined v-model="count"
                        label="الكمية"></v-text-field>
                    </v-col>
                    <v-col v-if="!is_magr" cols="12" sm="6" md="4">
                      <v-text-field :rules="rules.required" reverse outlined v-model="count_main" label=" ادخل الكمية "
                        type="numper"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <h3 v-if="editedItem.unit_price">المبلغ الكلي:{{total}} </h3>
                      <v-text-field v-else outlined reverse v-model="write_total" label="المبلغ" type="numper"></v-text-field>
                    </v-col>
                    <v-col v-if="editedItem.is_exported" cols="12" sm="6" md="4">
                      <v-autocomplete reverse outlined v-model="editedItem.safe_id" :items="my_safes"
                        :rules="[v => !!v || 'اختر القاصه']" required item-text="name" item-value="id" label="القاصة ">
                      </v-autocomplete>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close"> إلغاء </v-btn>
                <v-btn color="green" :disabled="!formIsValid" type="submit" @click="save"> حفظ </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <!-- delete dialog -->
              <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
                <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          الفقرة: <b class="mx-1">{{item.title}}</b>
          <span v-if="item.user"> تم الادخال بواسطة: <b class="mx-1"> {{item.user.name}} </b> </span>
          التاريخ الادخال: <b class="mx-1">{{item.created_at[1].slice(0,10)}}</b>
          الملاحظات: <b class="mx-1">{{item.notes}}</b>

          <span v-if="item.measurement">
            <v-chip class="mx-4" outlined color="blue"><b class="mx-1"> الطول: {{item.measurement.length}}</b></v-chip>
            <v-chip class="mx-4" outlined color="blue"> <b class="mx-1"> العرض:{{item.measurement.width}}</b></v-chip>
            <v-chip class="mx-4" outlined color="blue"> <b class="mx-1"> الارتفاع:{{item.measurement.height}}</b></v-chip> 
          </span>
        </td>
      </template>
      <template v-slot:[`item.invoice_date`]="{item}">
        <div></div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{item.invoice_date[0]}}</span>
          </template>
          <span>{{item.invoice_date[1].slice(0,10)}}</span>
        </v-tooltip>
      </template>
      <!-- actions of table -->
      <template v-slot:[`item.operation`]="{item}">
        <v-icon color="red" @click="deleteItem(item.id)"> mdi-delete </v-icon>
        <v-icon color="blue" @click="printInvoice(item)"> mdi-printer </v-icon>
      </template>
      <template v-slot:no-data>
        <v-card> <b>لايوجد</b>
        </v-card>
      </template>
    </v-data-table>
    <v-divider></v-divider> 
    <!-- not exported -->
    <v-data-table :headers="headers" :search="search"   :items="expense.not_exported" class="elevation-2" 
    :footer-props="{
          'items-per-page-text':'عدد العناصر',
            'next-icon':'$prev',
            'prev-icon':'$next', 
          }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- header -->
          <v-toolbar-title class="red--text"> المستحقات  </v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          الفقرة: <b class="mx-1">{{item.title}}</b>
          تم الادخال بواسطة: <b class="mx-1"> {{item.user.name}} </b>
          التاريخ الادخال: <b class="mx-1">{{item.created_at[1].slice(0,10)}}</b>
          الملاحظات: <b class="mx-1">{{item.notes}}</b>

          <v-chip v-if="item.measurement" class="mx-4" outlined color="yelloo"><b class="mx-1"> الطول:
              {{item.measurement.length}}</b></v-chip>
          <v-chip v-if="item.measurement" class="mx-4" outlined color="red"> <b class="mx-1">
              العرض:{{item.measurement.width}}</b></v-chip>
          <v-chip v-if="item.measurement" class="mx-4" outlined color="blue"> <b class="mx-1">
              الارتفاع:{{item.measurement.height}}</b></v-chip>
        </td>
      </template>
      <template v-slot:[`item.invoice_date`]="{item}">
        <div></div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{item.invoice_date[0]}}</span>
          </template>
          <span>{{item.invoice_date[1].slice(0,10)}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.operation`]="{item}">
        <v-icon color="red" @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-card>
          <v-btn> لا يوجد </v-btn>
        </v-card>
      </template>
    </v-data-table>


    <!-- to print -->
    <v-dialog v-model="dialogPrint" >
      <v-btn @click="dialogPrint=!dialogPrint" large color="error">اغلاق</v-btn>
      <div>
        <div v-if="expense.person" id="printHedear" class="p_right">
          <b>الاسم : {{expense.person.name}}</b>
          <br>
          <b>تاريخ البدء : {{expense.created_at[1].slice(0,10)}}</b>
          <br>
          <b v-if="expense.project"> مشروع : {{expense.project.name}}</b>

          <hr>
        </div>
        <v-data-table id="table" :headers="headersPrint" :search="search" :items="expense.exported" class="p_center"
          hide-default-footer disable-pagination>
          <template v-slot:top>
            <!-- header -->
            <br>
            <v-toolbar-title class="p_right"> <b> المدفوعات</b> </v-toolbar-title>
            <br>
          </template>
          <template v-slot:[`item.invoice_date`]="{item}"> 
            <span  >{{item.invoice_date[1].slice(0,10)}}</span> 
        </template>

          <template v-slot:no-data>
            لايوجد
          </template>

        </v-data-table>
      </div>
      <div>
        <v-data-table id="table1" :headers="headersPrint" :search="search" :items="expense.not_exported"
          hide-default-footer disable-pagination>
          <template v-slot:top>
            <!-- header -->
            <br>
            <v-toolbar-title class="p_right"><b>المشتريات او الاعمال </b></v-toolbar-title>
            <br>
          </template>
          <template v-slot:[`item.invoice_date`]="{item}"> 
            <span  >{{item.invoice_date[1].slice(0,10)}}</span> 
        </template>
          <template v-slot:no-data>
            لايوجد
          </template>

        </v-data-table>
        <div v-if="expense" class="p_right" id="footer">
          <br>
          <span class="p_box">المبلغ الكلي :{{expense.total}}</span>
          <span class="p_box">المبلغ المدفوع :{{expense.paid}}</span>
          <span class="p_box">المبلغ التبقي :{{expense.rest}}</span>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="dialogPrintInvoice">
      <v-btn @click="dialogPrintInvoice=!dialogPrintInvoice" large color="error">اغلاق</v-btn>

      <div v-if="expense.person" class="p_right" id="printInvoice">
        <h3 class="p_center">وصل صرف</h3> 
        <h4> رقم الفاتوره :<b>{{bill_number}}</b></h4>
        
        <h4>
            يصرف للسيد <b>{{expense.person.name}}</b>
           مبلغا وقدره <b>{{itemToPrint.total}}</b>
           وذلك عن <b>{{itemToPrint.title}}</b> 
          <br>
        </h4>
          <div v-if="expense.person" class="p_center" >
            <hr> 
        <div style="display: flex; justify-content: space-around;">
            <div>
              <h3>توقيع المستلم</h3>  
            </div>
            <div>
              <h3>توقيع المسلم</h3>  
            </div>
          </div>
      </div>
      </div> 
    </v-dialog>


  </v-container>
</template>
<script>
import printing from '@/plugins/print'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'expensesDetials',
  data: () => ({
    dialog: false,
    dialogSecendTable: false,
    dialogDelete: false,
    dialogPrint: false,
    dialogPrintInvoice: false,
    delete_id: null,
    bill_number:'',
    search: '',
    write_total:'',
    is_magr: false,
    count_main:'',
    itemToPrint: { measurement: '' },
    exported: [ { name: 'سلفة مدفوعة', id: 1 },{ name: 'مشتريات او اعمال', id: 0 }],
    rules: {
      required: [val => (val || '').length > 0 || 'ادخل الحقل '],
    },
    headers: [
      { text: 'الفقرة', value: 'title', align: 'start' },
      { text: 'المبلغ الكلي', value: 'total', sortable: false },
      { text: 'الكمية', value: 'measurement.count', sortable: false },
      { text: ' الوحدة', value: 'measurement.unit.name', sortable: false },
      { text: ' سعر الوحدة', value: 'measurement.unit_price', sortable: false },
      { text: ' القاصة', value: 'safe.name', sortable: false },
      { text: 'الملاحضات', value: 'notes', sortable: false },
      { text: 'التاريخ', value: 'invoice_date', sortable: false },
      { text: ' العمليات', value: 'operation', sortable: false, groupable: false },
    ],
    headersPrint: [
      { text: 'الفقرة', value: 'title', align: 'start', sortable: false },
      { text: 'المبلغ الكلي', value: 'total', sortable: false },
      { text: 'الكمية', value: 'measurement.count', sortable: false },
      { text: 'الوحدة', value: 'measurement.unit.name', sortable: false },
      { text: 'سعر الوحدة', value: 'measurement.unit_price', sortable: false },
      { text: 'الملاحضات', value: 'notes', sortable: false },
      { text: 'التاريخ', value: 'invoice_date', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      title: '',
      count:'',
      unit_price: '',
      measurement: {}
    },
    defaultItem: {
      title: '',
      count: '',
      unit_price: '',
    },
    printingArray:[{text:"printHedear",name:''},{ text:"table" , name:''},{ text:"table1" , name:' '},{ text:"footer" , name:' '}],
       
  }),

  computed: {
    ...mapGetters(['expense', 'units' , 'my_safes']),
    formTitle() {
      return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
    },
    total() { 
      return this.editedItem.unit_price * this.count 
       
    },
    count() {
      if (this.is_magr)
        return this.editedItem.length * this.editedItem.height * this.editedItem.width * this.editedItem.completion_rate / 100
      else
        return this.count_main
    },
    formIsValid() {
      return ( 
        Boolean(this.editedItem.invoice_date) &&  
        (

        Boolean(this.editedItem.is_exported && !!this.editedItem.safe_id ) || this.editedItem.is_exported == 0
        ) &&  
        (this.total || this.write_total)&&
        Boolean(this.editedItem.is_exported || this.editedItem.is_exported == 0)
      )
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  created() {
    this.expense=[]
  },

  mounted() {
    
    this.getExpense(this.$route.params.id)
    this.f_unit()
    this.f_my_safes()
  },
  
  methods: {
    ...mapActions(['getExpense', 'addInvoice', 'deleteInvoice','f_my_safes', 'editInvoice', 'f_unit']),
    print() { 
            this.dialogPrint = true;
            printing(this.printingArray)
            setTimeout(()=>{
              this.dialogPrint = false;
            },200) 
    },
    printInvoice(item) {
      axios.get('a/invoice/number/add/'+item.id)
        .then((res) => {
          this.bill_number=res.data.bill_number
          this.dialogPrintInvoice = true;
          this.itemToPrint = item
          printing([{text:"printInvoice",name:''}])
          })
          .catch(err => { 
              console.error(err);
          })
      
    },
    deleteItem(id) {
      this.delete_id = id
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      let params = {}
      params.id = this.delete_id 
      params.expenses_id=this.expense.id
      this.deleteInvoice(params)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {//save dialog add and edit

      //add new item   
      if (this.is_magr)
        this.editedItem.count = this.count;
      else
        this.editedItem.count = this.count_main;

      delete this.editedItem.measurement

      this.editedItem.expenses_id = this.$route.params.id 

      if(this.editedItem.unit_price)
       this.editedItem.total = this.total
      else
       this.editedItem.total = this.write_total;

      this.addInvoice(this.editedItem)

    },

  },

}
</script>