<template>
<v-container grid-list-xs>
    <v-row v-if="!added" class="my-3">
        <v-col dir="ltr" cols="12" sm="6" md="4">
            <v-autocomplete reverse outlined v-model="dailyPosition.project_id" :items="projects_n" :rules="[(v) => !!v || ' اختر المشروع ']" required item-text="name" item-value="id" label="اختر المشروع "></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4">
            <v-text-field outlined v-model="dailyPosition.title" :rules="rules.required" required label="الفقرة" reverse>
            </v-text-field>
        </v-col>
        <v-col dir="ltr" cols="12" sm="6" md="4">
            <v-autocomplete outlined auto-select-first reverse v-model="dailyPosition.payment_method" :items="cash" :rules="[(v) => !!v || v == 0 || 'ادخل الدفع']" required item-text="name" item-value="id" label="نوع الدفع"></v-autocomplete>
        </v-col>
        <v-col dir="ltr" cols="12" sm="6" md="4">
            <v-autocomplete reverse outlined v-model="dailyPosition.contractor_id" :items="contructors_n" item-text="name" item-value="id" label="المتعهد"></v-autocomplete>
        </v-col>
        <v-col dir="ltr" cols="12" sm="6" md="4">
            <v-autocomplete reverse outlined v-model="dailyPosition.construction_category_id" :items="constructions" :rules="[(v) => !!v || 'ادخل مرحلة العمل']" required item-text="name" item-value="id" label="مرحلة العمل"></v-autocomplete>
        </v-col>
        <v-col dir="ltr" cols="12" sm="6" md="4">
            <v-autocomplete reverse outlined v-model="dailyPosition.expenses_type_id" :items="expenseTypeProject" :rules="[(v) => !!v || 'ادخل التبويب']" required item-text="name" item-value="id" label="التبويب"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4">
            <v-text-field reverse outlined v-model="dailyPosition.date" label="تاريخ" type="date"></v-text-field>
        </v-col>
        <v-col dir="ltr" cols="12" sm="6" md="4">
            <v-autocomplete reverse outlined v-model="dailyPosition.unit" :items="units" required item-text="name" item-value="id" label="الوحدة "></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4">
            <v-checkbox label="خيارات اخرى" v-model="moreOptions"></v-checkbox>
        </v-col>

        <v-row v-if="moreOptions">

            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="dailyPosition.length" label="الطول" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="dailyPosition.width" label="العرض" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="dailyPosition.height" label="الارتفاع" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="dailyPosition.unit_price" label="سعر الوحدة" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="dailyPosition.count" label="الكمية" type="number"></v-text-field>
            </v-col>
        </v-row>
        <v-col v-if="this.total_amount" cols="12" sm="6" md="4">
            <h3>المبلغ : {{ this.total_amount }}</h3>
        </v-col>

        <v-col v-else cols="12" sm="6" md="4">
            <v-text-field outlined reverse v-model="dailyPosition.total_amount" label="المبلغ" type="number"></v-text-field>
        </v-col>
        <v-col dir="ltr" cols="12" sm="6" md="4">
            <v-autocomplete reverse outlined v-model="dailyPosition.weather_id" :items="weather" item-text="title" item-value="id" label="حالة الطقس"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field outlined reverse v-model="dailyPosition.out_bill_number" label="رقم الوصل" type="text"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field outlined reverse v-model="dailyPosition.notes" label="الملاحضات" type="text"></v-text-field>
        </v-col>
        <v-col v-if="isError" cols="12">
            <v-alert type="error"> بعض المدخلات خطأ </v-alert>
        </v-col>
        <v-col cols="12">
            <v-btn color="success" @click="save" :disabled="!formIsValid" large>أرسال</v-btn>
        </v-col>
    </v-row>
    <v-dialog v-model="added" scrollable max-width="500px" transition="dialog-transition">
        <v-card>
            <v-card-title primary-title>
                تم حفظ الموقف
            </v-card-title>
            <v-card-actions>
                <v-btn to="/dailyPosition" color="error">رجوع</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="saveSuccess" color="success">موافق </v-btn>

            </v-card-actions>
        </v-card>

    </v-dialog>

</v-container>
</template>

<script>
import router from "@/router";
import axios from "axios";
import {
    mapActions,
    mapGetters
} from "vuex";
export default {
    name: "addDailyPosition",
    data() {
        return {
            added: false,
            isError: false,
            id: null,
            moreOptions: false,
            weather: [{
                    id: 1,
                    title: "مشمس",
                },
                {
                    id: 2,
                    title: "غائم",
                },
                {
                    id: 3,
                    title: "ماطر",
                },
                {
                    id: 4,
                    title: "عاصف ",
                },
                {
                    id: 5,
                    title: "مثلج ",
                },
                {
                    id: 6,
                    title: "ضباضي",
                },
            ],
            rules: {
                required: [(val) => (val || "").length > 0 || "ادخل الحقل "],
            },
            cash: [{
                    id: 1,
                    name: "نقد"
                },
                {
                    id: 0,
                    name: "اجل"
                },
            ],
            dailyPosition: {
                title: "",
                payment_method: "",
                contractor_id: "",
            },
        };
    },
    mounted() {
        this.id = this.$route.query.id
        this.fetchDaliyPosition()
        this.f_constructors_n();
        this.f_constructions();
        this.f_expensesTypeProject();
        this.f_unit();
        this.f_projects_n();
    },
    computed: {
        ...mapGetters([
            "contructors_n",
            "constructions",
            "expenseTypeProject",
            "units",
            "projects_n",
        ]),
        total_amount() {
            return (this.dailyPosition.unit_price * this.dailyPosition.count)
        },
        formIsValid() {
            return (
                Boolean(this.dailyPosition.title) &&
                Boolean(this.dailyPosition.date) &&
                Boolean(this.dailyPosition.construction_category_id)
            );
        },
    },
    methods: {
        ...mapActions([
            "f_constructors_n",
            "f_constructions",
            "f_projects_n",
            "f_expensesTypeProject",
            "f_unit",
        ]),
        fetchDaliyPosition() {
            if (this.id) {
                axios
                    .get("a/daily-position/show/" + this.id)
                    .then((res) => {
                        console.log(res.data);
                        this.dailyPosition = res.data.daily_position
                        this.total_amount = res.data.daily_position.total_amount
                    })
                    .catch((err) => {
                        console.error(err);
                    });

            }

        },
        save() {
            if (this.total_amount)
                this.dailyPosition.total_amount = this.total_amount;

            if (this.id) {
                axios
                    .post("a/daily-position/update/" + this.id, this.dailyPosition)
                    .then(() => {
                        this.added = true
                        this.isError = false;
                    })
                    .catch((err) => {
                        console.error(err);
                        this.inputError = true;
                    });
            } else {

                console.log('daly', this.dailyPosition);

                axios
                    .post("a/daily-position/add", this.dailyPosition)
                    .then(() => {
                        this.added = true
                        this.isError = false;
                    })
                    .catch((err) => {
                        console.error(err);
                        this.isError = true;
                    });
            }

        },
        saveSuccess(){
            this.added = false ; 
            this.dailyPosition={}
            router.push('/addDailyPosition')
        }
    },
};
</script>
