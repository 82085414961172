import axios from 'axios'; 

const state={ 
    dailyPosition:[], 
    notifications:[],
    notificationsCount:'',
    events:[],
 
}

const  getters={ 
    dailyPosition:(state)=>state.dailyPosition,  
    notifications:(state)=>state.notifications,  
    notificationsCount:(state)=>state.notificationsCount,  
    events:(state)=>state.events,  
}

const mutations= { 
    setDailyPosition:(state,dailyPosition)=>state.dailyPosition=dailyPosition, 
    setNotifications:(state,notifications)=>state.notifications=notifications, 
    setNotificationsCount:(state,notificationsCount)=>state.notificationsCount=notificationsCount, 
    setEvents:(state,events)=>state.events=events, 
} 
const actions={   
    read({dispatch},id){
        axios.get('a/notfic/watched/'+id)
        .then(() => {
            dispatch('f_notifications')
        })
        .catch(err => {
            console.error(err); 
        })
    },
   f_notifications({commit}){
        axios.get('a/notfic/get')
        .then(res => {
            commit('setNotifications',res.data.notifications)
            commit('setNotificationsCount',res.data.count_not_watched)
        })
        .catch(err => {
            console.error(err); 
        })
   },
   f_dailyPosition({commit},params){
    axios.post('a/daily-position',params)
    .then(res => {
        commit('setDailyPosition',res.data.daily_positions)
    })
    .catch(err => {
        console.error(err); 
    })
   },
   f_events({commit},params){
    axios.get('a/calendar_event',params)
    .then(res => {
        commit('setEvents',res.data.calendar_events)
    })
    .catch(err => {
        console.error(err); 
    })
   }
}

export default {
    getters,
    state,
    actions,
    mutations
}