<template>
    <v-container fluid>
        <!-- <v-row   class="text-center mt-3"> -->
            <!-- <v-col class="my-5" cols="12" sm="6" md="4">
              <v-btn rounded dark to="/employeeInvoice" class="mx-3  py-5 my_btn elevation-0 bg-main black--text"><h3><v-icon>mdi-file-document-outline</v-icon>  سلف الموظفين  </h3></v-btn>
           </v-col> -->
           <!-- <v-col class="my-5"  cols="12" sm="6" md="4">
              <v-btn rounded  to="/officeAccounts"   class="mx-3 py-5 my_btn elevation-0 bg-main blue--text"><h3><v-icon>mdi-cash</v-icon> حسابات المكتب </h3></v-btn>
           </v-col> --> 
           <!-- <v-col class="my-5 hiden"  cols="12" sm="6" md="4">
              <v-btn rounded  to="/expenceInfo"   class="mx-3 py-5 my_btn elevation-0 bg-main blue--text"><h3><v-icon>mdi-plus-thick</v-icon> أضافة فقرة </h3></v-btn>
           </v-col>
            <v-col class="my-5 hiden"  cols="12" sm="6" md="4">
              <v-btn rounded   to="/showAccount"   class="mx-3  py-5 my_btn elevation-0 bg-main green--text"><h3><v-icon>mdi-list-status</v-icon> كشف حساب </h3></v-btn>
           </v-col> 
           
        </v-row> 
        <hr> -->
   
        <h2 class="text-center my-5">الميزانية</h2>
        <v-row class="text-center  ">  
          <v-col>
            <table id="customers">
                <tr>
                    <th>الاصول</th>
                    <th>المبلغ</th>
                </tr> 
                <tr > 
                    <td> <h3>النقد النهائي  </h3> </td>  
                    <td> <h3>{{ budget.assets.final_cash.final_cash }}</h3>  </td> 
                </tr>
                <tr > 
                  <td> <h3>المتبقي من سلف الموظفين   </h3> </td>  
                  <td> <h3>{{ budget.assets.sum_rest_emp_self }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>المبالغ المستحقة على العملاء    </h3> </td>  
                  <td> <h3>{{ budget.assets.sum_project_box_negative }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>مجموع المبالغ في القاصات    </h3> </td>  
                  <td> <h3>{{ budget.assets.sum_safe_amounts }}</h3>  </td> 
              </tr> 
              <tr > 
                  <td> <h2>مجموع الاصول    </h2> </td>  
                  <td> <h2>{{ budget.assets.sum_assets }}</h2>  </td> 
              </tr>
               
            </table>  
            </v-col>
            <v-col>

              <table id="customers">
                <tr>
                    <th>الالتزامات</th>
                    <th>المبلغ</th>
                </tr> 
                <tr > 
                    <td> <h3>المتبقي لاصحاب المشاريع  </h3> </td>  
                    <td> <h3>{{ budget.obligations.sum_project_box_positive }}</h3>  </td> 
                </tr>
                <tr > 
                  <td> <h3>ديوين المقاولين   </h3> </td>  
                  <td> <h3>{{ budget.obligations.sum_rest_debit_expenses }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>مجموع الارباح    </h3> </td>  
                  <td> <h3>{{ budget.obligations.profit_company }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>راس المال</h3> </td>  
                  <td> <h3>{{ budget.obligations.sum_capital_amounts }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h2>مجموع  الالتزامات    </h2> </td>  
                  <td> <h2>{{ budget.obligations.sum_obligations }}</h2>  </td> 
              </tr>
               
            </table>   
          </v-col>
        </v-row>
        <v-row class="my-4">
          <v-col>
            <h3 class="text-center mb-1">تفاصيل النقد النهائي</h3>
              <table id="customers">
            <tr>
                    <th>الاسم</th>
                    <th>المبلغ</th>
                </tr> 
                    <tr >
                    <td> الصافي الكلي للمشاريع </td>
                    <td> {{ budget.assets.final_cash.sum_project_box }} </td>
                </tr>
                <tr >
                    <td> الوارد  الشركة من غير المشاريع  </td>
                    <td> {{ budget.assets.final_cash.sum_company_import }} </td>
                </tr>
                <tr >
                    <td> المصروف الكلي للشركة  </td>
                    <td> {{ budget.assets.final_cash.sum_export_company }} </td>
                </tr>
               
                <tr > 
                    <td> <h3>النقد النهائي  </h3> </td>  
                    <td> <h3>{{ budget.assets.final_cash.final_cash }}</h3>  </td> 
                </tr>
                  </table>
          </v-col>
          <v-col></v-col>
        
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name:'accountsPage',
    computed:mapGetters(['budget']),
    methods:{
      ...mapActions(['f_budget'])
    },
    mounted(){
      this.f_budget()
    }
    
}
</script>

<style scoped>
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #04AA6D;
    color: white;
}
</style>
