<template>
<div>
    <v-container class="text-center my-5">
        <!--    <v-col  >
            <v-btn to="/projectContract" rounded dark  class="mx-3 my_btn elevation-0 bg-main blue--text"  ><v-icon>mdi-office-building</v-icon>عقد المشروع</v-btn>
        </v-col> 
        <v-col>
            <v-btn to="/constractorContract" rounded dark  class="mx-3 my_btn elevation-0 bg-main green--text"  ><v-icon>mdi-file-document-outline</v-icon>عقد اتفاق العمل</v-btn>
        </v-col> -->
        <!-- <v-col>
            <v-btn rounded dark class="mx-3 my_btn elevation-0 bg-main dark_blue--text"><v-icon>mdi-office-building-cog</v-icon>أنشاء مشروع تخميني</v-btn>
        </v-col> -->
        <v-row>
            <v-col cols="" >
                <v-file-input v-if="change" reverse label="اضغط لتحميل الصوره" @change="uploadFile($event)"></v-file-input>
                <v-btn @click="change=!change" color="blue">
                    <v-icon>mdi-image</v-icon> تغيير الصوره
                </v-btn>
            </v-col>
            <v-col>
                <h4 class="text-center">شعار الشركة</h4>
                <img class="text-center" :src="settings.company_photo" width="200" height="200" alt="">
            </v-col>

        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <v-text-field outlined v-model="settings.company_name" :rules="rules.required" required label="اسم الشركة" reverse>
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field outlined v-model="settings.company_address" :rules="rules.required" required label="العنوان" reverse>
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field outlined v-model="settings.company_phone" :rules="rules.required" required label="رقم الهاتف" reverse>
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field outlined v-model="settings.company_boss_name" :rules="rules.required" required label="اسم المدير" reverse>
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field outlined v-model="settings.company_email" :rules="rules.required" required label="البريد الالكتروني" reverse>
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field outlined v-model="settings.second_party" :rules="rules.required" required label="الوصف" reverse>
                </v-text-field>
            </v-col>

        </v-row>
        <v-alert type="success" v-if="Updated">
            تم الحفظ
        </v-alert>
        <v-row>
            <v-btn color="success" @click="sendData">حفظ</v-btn>
        </v-row>
        <h3 class="text-left mt-5">اصدار البرنامج ({{ver}})</h3>
    </v-container>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name: "mainSetting",
    data() {
        return {
            ver: '',
            settings: '',
            Updated: false,
           formData:new FormData,
           change:false,

            rules: {
                required: [(val) => (val || "").length > 0 || "ادخل الحقل "],
            },
        }
    },
    created() {
        axios.get('a/setting/select/ver')
            .then(res => {
                this.ver = res.data.ver
            })
            .catch(err => {
                console.error(err);
            })
        this.getData()

    },
    methods: {

        getData() {
            axios
                .get("a/setting/get")
                .then((res) => {
                    this.settings = res.data.setting
                    console.log('sadsad', this.settings)
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sendData() {
            this.Updated = false
            delete this.settings.company_photo
            axios
                .post("a/setting/update_all", this.settings)
                .then(() => {
                    this.Updated = true
                    this.getData()
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        uploadFile(e){
            console.log(e)
          this.formData.append('company_photo',e)
          axios.post("a/setting/company_photo", this.formData)
                .then(() => { 
                    this.getData()
                })
                .catch((err) => {
                    console.log(err);
                });

        }
        //     saveUploadFile(){

        //     this.formData.append('id',this.editedItem.id)
        //     this.uploadPersonImage(this.formData)    

        //    },
        //    uploadFile(f){ 
        //     this.formData.append('company_photo',f) 
        //   },
    }

}
</script>
