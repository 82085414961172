<template>
  <v-container class="fs-2" fluid> 
    <v-data-iterator  
      :items="projects"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page" 
      :search="search"  
      hide-default-footer
    >
      <template v-slot:header> 
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider> 
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            sm="12"
            md="6"
            lg="3"
          >
            <v-card class="bg-main my_btn" >
              <v-card-title class="subheading font-weight-bold">
                {{ item.name }} 
                <v-spacer class="white--text"></v-spacer>
               <v-btn icon class="my_btn" :to="'/projectPage/'+item.id" color="success aline-end"><v-icon>mdi-book-arrow-left</v-icon></v-btn> 
              </v-card-title> 
              <v-divider></v-divider>
              <v-list class="bg-main"  dense>
                <!-- data -->
                <v-list-item>
                  <v-list-item-content>العنوان:</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.address }}</b> 
                  </v-list-item-content>
                </v-list-item> 
                <!-- <v-list-item>
                  <v-list-item-content> النسبة:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <b>{{ item.profit_percent }}</b>
                  </v-list-item-content>
                </v-list-item>  -->
                <v-list-item>
                  <v-list-item-content>نوع المشروع:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <b>{{ item.project_type.name }}</b>
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item class="green--text" >
                  <v-list-item-content>مجموع  الدفعات المستلمة:</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.report.total_import }} دع</b> 
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item class="red--text" >
                  <v-list-item-content >مجموع المبالغ المصروفة:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <b>{{ item.report.total_export }} دع</b>
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item>
                  <v-list-item-content>ارباح المكتب:</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.report.profit }} دع</b> 
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item>
                  <v-list-item-content>نسبة فائدة المكتب:</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.profit }} %</b> 
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item :class="{'red white--text': item.report.import_sub_export  < 2000000 }">
                  <v-list-item-content>  صافي المبلغ المتبقي:</v-list-item-content>
                  <v-list-item-content class="align-end">
                  <b>{{ item.report.project_box }} دع</b>  
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item :class="{'red white--text': item.report.import_sub_export  < 1 }">
                  <v-list-item-content> اجمالي المبلغ المتبقي:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <b>{{ item.report.import_sub_export }} دع</b>
                  </v-list-item-content>
                 </v-list-item>
                 <v-list-item >
                  <v-list-item-content> الانجاز الزمني :</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.report.time_achievement  }} </b> 
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item >
                  <v-list-item-content> الانجاز المالي :</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.report.financial_achievement  }} </b> 
                  </v-list-item-content>
                </v-list-item> 
                <!-- <v-list-item>
                  <v-list-item-content>الخلاصة : </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <b>{{ item.report.import_sub_percentage }} دع</b>
                  </v-list-item-content>
                </v-list-item> -->
              
                <!-- end data -->

              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row
          class="mt-2"
          align="center"
          justify="center"
        >
          <span class="grey--text">عدد المشاريع في الصفحة</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span
            class="mr-4
            grey--text"
          >
           {{ page }}   من   {{ numberOfPages }}
          </span>
          <v-btn
            fab
            dark
            color="green darken-1"
            class="mx-2"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            class="mx-2"
            color="green darken-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "projectsPage",
  data(){
      return{
        search: '',
        itemsPerPageArray: [4, 8, 12],
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 4,
        sortBy: 'name',
        keys: [
          'Name', 
        ],
       
      }
  },
  computed:{
    ...mapGetters(['projects']),
      numberOfPages () {
        return Math.ceil(this.projects.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== 'Name')
      },
  },
  methods:{
    ...mapActions(['f_projects']),
    nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
  },

  mounted(){
    this.f_projects()
  }
  
};
</script>
