<template>
    <v-container   fluid>   
    <template> 
      <v-dialog v-model="dialogSorting" transition="dialog-transition">
            <v-card>
                <v-card-title primary-title> البحث حسب </v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row> 
                            <v-col dir="ltr" cols="12" sm="6" md="4">
                                <v-autocomplete small outlined auto-select-first reverse v-model="search.from_safe_id" :items="all_safes" item-text="name" item-value="id" label="التحويل من"></v-autocomplete>
                            </v-col>
                            <v-col dir="ltr" cols="12" sm="6" md="4">
                              <v-autocomplete small outlined auto-select-first reverse v-model="search.to_safe_id" :items="all_safes" item-text="name" item-value="id" label="التحويل الى"></v-autocomplete>
                            </v-col> 
                            <v-col dir="ltr" cols="12" sm="6" md="4"> 
                                <v-text-field reverse outlined v-model="search.from_amount" label="مبلغ تم تحويله" type="number"></v-text-field>
                            </v-col>
                            <v-col dir="ltr" cols="12" sm="6" md="4">
                              <v-text-field reverse outlined v-model="search.from_created_at" label=" من تاريخ التحويل" type="date"></v-text-field>
                             </v-col>
                             <v-col dir="ltr" cols="12" sm="6" md="4">
                              <v-text-field reverse outlined v-model="search.to_created_at" label=" الى تاريخ التحويل" type="date"></v-text-field>
                             </v-col>
                            <v-col dir="ltr" cols="12" sm="6" md="4">
                                <v-autocomplete reverse outlined v-model="search.user_id" :items="users_name" multiple item-text="name" item-value="id" label="التحويل بواسطة"></v-autocomplete>
                            </v-col>  
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialogSorting = !dialogSorting" dark color="red">الغاء</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" @click="search={}" color="blue">تفريغ</v-btn>
                    <v-btn @click="searchTrans()" color="success">بحث</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
  <!-- main table -->
    <v-data-table    
      :headers="headers" 
      :items="transSafe"    
       class="my_btn bg-main my-2"
      :footer-props="{
            'items-per-page-text':'عدد العناصر',
              'next-icon':'$prev',
              'prev-icon':'$next', 
            }"
    > 
      <template  v-slot:top>
        <v-toolbar
          flat  class=" bg-main"
        >
        <!-- header -->
          <v-toolbar-title>تحويلات القاصات </v-toolbar-title>
          <v-btn color="success" small class="my_btn" @click="dialogSorting = !dialogSorting">
                        <v-icon>mdi-sort-bool-ascending-variant</v-icon> البحث
                        التفصيلي
                    </v-btn> 
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>   
           <v-spacer></v-spacer>
          <!-- add and delete dialog -->
          <v-dialog 
            v-model="dialog"
            fullscreen
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                reverse 
                color="blue"
                dark 
                class="mb-2 my_btn elevation-0"
                v-bind="attrs"
                v-on="on"
                @click="checkPermissions('safe-transaction-add')"
              >تحويل<v-icon>mdi-swap-horizontal</v-icon>  
              </v-btn> 
              <!-- print btn -->
              <v-btn 
                class="mb-2 mx-2 my_btn elevation-0" 
                color="main" 
  
                @click="print()"
              >  <v-icon color="blue" >mdi-printer-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <!-- dialog add and edit  -->
              <v-card-text>
                <v-container>
                  <v-row > 
                    <v-col dir="ltr" cols="12"  sm="6"  md="4" >
                      <v-autocomplete 
                        reverse
                        outlined 
                        v-model="editedItem.from_safe_id" 
                        :items="my_safes" 
                        :rules="[v => !!v || 'اختر القاصه']" 
                        item-text="name"
                        item-value="id"
                        label="من  " 
                      ></v-autocomplete>
                     </v-col>
                     <v-col dir="ltr" cols="12"  sm="6"  md="4" >
                      <v-autocomplete 
                        reverse
                        outlined 
                        v-model="editedItem.to_safe_id" 
                        :items="all_safes" 
                        :rules="[v => !!v || 'اختر القاصه']" 
                        item-text="name"
                        item-value="id"
                        label="الى " 
                      ></v-autocomplete>
                     </v-col>
                      <v-col v-if="editedIndex==-1" cols="12"  sm="6"  md="4" >
                        <v-text-field
                          reverse
                          outlined
                          v-model="editedItem.amount"
                          label="المبلغ " 
                          type="number"
                        ></v-text-field>
                      </v-col> 
                    </v-row> 
                   
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" large text @click="dialog=!dialog" > إلغاء </v-btn>
                <v-btn color="green white--text" large    :disabled="!formIsValid"  type="submit"  @click="save" > حفظ </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> 
        </v-toolbar>
      </template>
      >
      <!-- actions of table --> 
      <template  v-slot:[`item.operation`]="{item}" >
        <v-icon color="blue" @click="printTrans(item)"> mdi-printer </v-icon>
      </template>
      <template v-slot:no-data>
        لا توجد بيانات
      </template>
    </v-data-table>
    
   </template>
  
  
   <!-- to print -->
   <v-dialog v-model="dialogPrint">
    <v-btn @click="dialogPrint=!dialogPrint" large color="error">اغلاق</v-btn>
  
    <h3   id="p_title"  class="p_center">القاصات</h3> 
  
     <div  >
      <v-data-table 
      id="table" 
      :headers="headersPrint" 
      :items="transSafe"   
       class="elevation-1" 
       hide-default-footer
       disable-pagination 
      > 
      <template v-slot:[`item.accessed_users`]="{item}">
     
        <div v-for="( a , index ) in item.accessed_users" :key="index">
        {{ index+1 }} - {{ a.name }} 
        </div>
    </template>
      </v-data-table>
    </div>
    </v-dialog>
    <v-dialog v-model="dialogPrintTrans">
      <v-btn @click="dialogPrintTrans=!dialogPrintTrans" large color="error">اغلاق</v-btn>

      <div v-if="itemToPrint" class="p_right" id="printTrans">
        <h3 class="p_center">وصل تحويل بين القاصات</h3> 
        <h4> 
           
           تم تحويل مبلغا وقدره <b>{{itemToPrint.amount}}</b>
           من <b v-if="itemToPrint.from_safe">{{itemToPrint.from_safe.name}}</b>
           الى <b v-if="itemToPrint.to_safe">{{itemToPrint.to_safe.name}}</b>
            في <b>{{itemToPrint.created_at}}</b> 
            التحويل بواسطة <b v-if="itemToPrint.user">{{itemToPrint.user.name}}</b> 
          <br>
        </h4>
          <div class="p_center" >
          <hr>
          <div style="display: flex; justify-content: space-around;">
            <div>
              <h3>توقيع المحاسب</h3>  
            </div>
            <div>
              <h3>توقيع المدير</h3>  
            </div>
          </div>
      </div>
      </div> 
    </v-dialog>
  </v-container>
  
  
  </template>
  <script>  
  import printing from '@/plugins/print';
import tools from '@/plugins/tools';
  import { mapActions, mapGetters } from 'vuex'  
// import axios from 'axios';
    export default { 
      name:'transSafe',
      props:['id'],
      data: () => ({ 
        dialogPrint:false,   
        dialog:'', 
        dialogSorting: false,
        dialogPrintTrans:false,
        itemToPrint:{},
        printingArray:[{text:"printTrans",name:'العنوان'}],
        rules: { 
            required: [val => (val || '').length > 0 || 'ادخل الحقل '],
          }, 
        headers: [
          { text: 'من',value: 'from_safe.name',align:'start',sortable: false },   
          { text: 'الى', value: 'to_safe.name' , groupable: false}, 
          { text: 'المبلغ', value: 'amount' , groupable: false},  
          { text: 'بوسطة', value: 'user.name' , groupable: false},  
          { text: 'تاريخ', value: 'created_at' , groupable: false},  
          { text: '', value: 'operation' , groupable: false},  
        ],
        headersPrint: [  
        { text: 'من',value: 'from_safe.name',align:'start',sortable: false },   
          { text: 'الى', value: 'to_safe.name' , groupable: false ,sortable: false}, 
          { text: 'المبلغ', value: 'amount' , groupable: false,sortable: false},  
          { text: 'بوسطة', value: 'user.name' , groupable: false,sortable: false},  
          { text: 'تاريخ', value: 'created_at' , groupable: false,sortable: false}, 
        ],  
        editedIndex: -1,
        editedItem: {
            name: '',
            balance: '',
            accessed_users: '',  
        },
        defaultItem: {  
            name: '',
            balance: '',
            accessed_users: '',  
        },  
        search: { 
            },
      }),
  
      computed: {
        ...mapGetters(['transSafe','all_safes','users_name','my_safes']), 
        formTitle () {
          return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
        },
        formIsValid () {
          return ( true )
          }
      },
   
      created(){
        this.f_transSafe() 
        this.f_my_safes()
        this.f_all_safes()
        this.f_users_name()
      }, 
      methods: { 
        ...mapActions(['f_transSafe', 'f_all_safes','f_users_name','f_my_safes','transMoney']),  
        print() {
           this.dialogPrint=true ;
           printing(["p_title","table",'p_footer'])
          },  
        printTrans(item){ 

          this.dialogPrintTrans=true ;
          this.itemToPrint=item
          console.log(this.itemToPrint);
          printing(this.printingArray)

          setTimeout(()=>{ 
            this.dialogPrintTrans = false;
          },200)
        },
        checkPermissions(per){tools.checkPermissions(per)},
        save () {//save dialog add   
            this.transMoney(this.editedItem) 
            this.dialog=false
        },
        searchTrans(){
          this.f_transSafe(this.search)
          this.dialogSorting=false
        }
      },
    }
  </script>
  