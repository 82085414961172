<template>
<v-container fluid>
    <h2 class="text-center my-2">كشف حساب </h2>

    <v-dialog v-model="dialogSorting" transition="dialog-transition">
        <v-card>
            <v-card-title primary-title>
                البحث حسب
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field outlined v-model="searchExpenses.title" label="الفقرة" reverse>
                            </v-text-field>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined multiple chips v-model="searchExpenses.project_ids" :items="projects_n" item-text="name" item-value="id" label="اختر المشروع "></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete small outlined auto-select-first reverse v-model="searchExpenses.is_cash" :items="cash" item-text="name" item-value="id" label="نوع الدفع"></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined v-model="searchExpenses.is_complete" :items="complete" item-text="name" item-value="id" label="مكتمل">
                            </v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined chips multiple v-model="searchExpenses.person_ids" :items="contructors_n" item-text="name" item-value="person_id" label="المتعهد"></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined chips multiple v-model="searchExpenses.person_ids" :items="employees_n" item-text="name" item-value="person_id" label="سلفة موظف   "></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined chips multiple v-model="searchExpenses.construction_category_ids" :items="constructions" item-text="name" item-value="id" label="مرحلة العمل"></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined chips multiple v-model="searchExpenses.expense_type_ids" :items="expensesType" item-text="name" item-value="id" label="التبويب"></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined chips multiple v-model="searchExpenses.user_ids" :items="users_name" item-text="name" item-value="id" label="مستخدم"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field reverse outlined v-model="searchExpenses.from_invoice" label=" من تاريخ" type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field reverse outlined v-model="searchExpenses.to_invoice" label="الى تاريخ" type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field reverse outlined v-model="searchExpenses.out_bill_number" label="رقم الوصل" type="numper"></v-text-field>
                        </v-col>

                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined multiple v-model="searchExpenses.safe_ids" :items="my_safes" :rules="[v => !!v || 'اختر القاصه']" required item-text="name" item-value="id" label="القاصة ">
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="dialogSorting=!dialogSorting" dark color="red">الغاء</v-btn>
                <v-btn dark class="mx-5" @click="searchDefault" color="red">تفريغ <v-icon color="white">mdi-refresh</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="searchEx()" color="success">بحث</v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
    <template>
        <!-- main table -->
        <v-data-table :headers="headers" show-expand :items="expenses" show-group-by class="my_btn bg-main" :footer-props="{
        'items-per-page-text':'عدد العناصر',
            'next-icon':'$prev',
            'prev-icon':'$next', 
        }">
            <template v-slot:top>
                <v-toolbar flat class=" bg-main">
                    <!-- header -->
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn color="success" small class="my_btn" @click="sorting()">
                        <v-icon>mdi-sort-bool-ascending-variant</v-icon> البحث التفصيلي
                    </v-btn> 
                    <v-spacer></v-spacer>
                    <v-btn rounded color="main" class="elevation-0 mb-2 mx-2 my_btn" @click="print()">
                        <v-icon color="blue">mdi-printer-outline</v-icon>
                    </v-btn>
                    <!-- add and delete dialog -->
                    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
                        <template>
                            <!-- print btn -->
                            <v-btn class="mb-2 mx-2 my_btn elevation-0" color="main" @click="print()">
                                <v-icon color="blue">mdi-printer-outline</v-icon>
                            </v-btn>

                            <v-checkbox color="success" v-model="isPrint" large label=" طباعة الخلاصة"></v-checkbox>
                        </template>
                    </v-dialog>

                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <!-- delete dialog -->
                            <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
                                <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            >
            <template v-slot:[`group.header`]="{headers, isOpen, toggle ,group,remove}">
                <th class="text-right" :colspan="headers.length">
                    <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{group}}
                    <v-icon @click="remove">mdi-close</v-icon>
                </th>
            </template>
            <!-- more detials -->
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    تم الادخال بواسطة: <b class="mx-1"> {{item.user.name}} </b>  

                </td>
            </template>
            <!-- time -->

            <template v-slot:[`item.first_invoice_date`]="{item}"> 
                  <span v-if="item.first_invoice_date">{{item.first_invoice_date[1].slice(0,10)}}</span> 
            </template>

            <template v-slot:[`item.is_complete`]="{item}">
                <v-chip small color="green" text-color="white" v-if="item.is_complete">مكتمل</v-chip>
                <v-chip small color="lime" v-else>غير مكتمل</v-chip>
            </template>
            <!-- type  -->
            <template v-slot:[`item.is_cash`]="{item}">
                <v-chip small color="red" text-color="white" v-if="item.is_cash==0">آجل</v-chip>
                <v-chip small color="cyan" text-color="white" v-if="item.is_cash==1">نقد</v-chip>
            </template>
            <!-- actions of table -->
            <template v-slot:[`item.operation`]="{item}">
                <!-- <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon> -->
                <v-icon color="blue" @click="openExpense(item.id)"> mdi-open-in-new</v-icon>
                <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
                <v-icon v-if="item.rest!='0'" class="mr-2" @click="payAmonutLocal(item.id)"> mdi-cash </v-icon>
            </template>
            <template v-slot:no-data>
                لا توجد بيانات
            </template>
        </v-data-table>
        <v-row class="my-5 text-center">
            <v-col cols="12" md="4" class="elevation-0">
                <v-alert type="error" class="my_btn" border="right">
                    المبلغ الكلي المتبقي: {{expensesReport.rest_sum}}
                </v-alert>
            </v-col>
            <v-col cols="12" md="4" class="elevation-0">
                <v-alert type="success" class="my_btn" border="right">
                    المبلغ الكلي المدفوع: {{expensesReport.paid_sum}}
                </v-alert>
            </v-col>
            <v-col cols="12" md="4" class="elevation-0">
                <v-alert type="info" class="my_btn" border="right">
                    المبلغ الكلي المستحق: {{expensesReport.total_export_sum}}
                </v-alert>
            </v-col>

        </v-row>
    </template>
    <!-- pay dialog -->
    <v-dialog v-model="payDialog" max-width="900px">
        <v-card>
            <v-card-title text class="text-h6">هل تريد تسوية الفقره</v-card-title>
            <v-card-text>
                <v-col dir="ltr" cols="12" sm="6" md="4">
                    <v-autocomplete reverse outlined  v-model="payExpence.safe_id" :items="my_safes" :rules="[v => !!v || 'اختر القاصه']" required item-text="name" item-value="id" label="القاصة ">
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field reverse outlined v-model="payExpence.invoice_date" label="  التاريخ" type="date"></v-text-field>
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="payDialog=false">الغاء</v-btn>
                <v-btn color="green darken-1" text @click="payConfirm">موافق</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- dialogPrintSet -->
    <v-dialog v-model="dialogPrintSet" max-width="900px">
            <v-card>
                <v-card-title text class="text-h6">اختر الاعمده للطباعة </v-card-title>
                <v-row class="mx-5">
                    <v-col class="mx-5" dir="ltr" cols="12" md="6">
                        <v-combobox reverse outlined v-model="headersPrint" :items="headers" multiple item-text="text" item-value="value" label="اختر العمود"></v-combobox>
                    </v-col> 
                    <v-col class="mx-5" dir="ltr" cols="12" md="6">
                        <v-combobox reverse outlined v-model="printingArray" :items="printingArrayChoies" multiple item-text="name" item-value="text" label="اختر باقي التفاصيل"></v-combobox>
                    </v-col> 
                </v-row>
                <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="dialogPrintSet=!dialogPrintSet">الغاء</v-btn>
                  <v-btn rounded color="main" class="elevation-0 mb-2 mx-2  " @click="printOk()">
                    طباعة  
                        <v-icon color="blue">mdi-printer-outline</v-icon>
                  </v-btn> 
                  <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

    <!-- to print -->
    <v-dialog v-model="dialogPrint">
        <v-btn @click="dialogPrint=!dialogPrint" large color="error">اغلاق</v-btn>

        <div>
            <v-data-table id="table" :headers="headersPrint" :items="expenses" class="elevation-1" hide-default-footer disable-pagination>
                <template v-slot:[`item.is_complete`]="{item}">
                    <div v-if="item.is_complete">مكتمل</div>
                    <div v-else>غير مكتمل</div>
                </template>
                <template v-slot:[`item.is_cash`]="{item}">
                    <div v-if="item.is_cash==1">نقد</div>
                    <div v-if="item.is_cash==0">أجل</div>
                </template>
                <template v-slot:[`item.first_invoice_date`]="{item}"> 
                  <span v-if="item.first_invoice_date">{{item.first_invoice_date[1].slice(0,10)}}</span> 
                </template>

            </v-data-table>
                 <div class="p_right" id="footer">
                    <br>
                    <span class="p_box">المبلغ الكلي :{{expensesReport.total_export_sum}}</span> 
                    <span class="p_box">مجموع المبلغ المدفوع :{{expensesReport.paid_sum}}</span> 
                    <span class="p_box">مجموع المبلغ المتبقي :{{expensesReport.rest_sum}}</span> 
                 </div>
                  <div id="geter_reco" class="p_center" >
                    <hr>
                    <h3>توقيع المهندس</h3> 
                  </div>
        </div>
    </v-dialog>
</v-container>
</template>

<script>
import printing from '@/plugins/print'
import tools from '@/plugins/tools'
import router from '@/router'
import {
    mapActions,
    mapGetters
} from 'vuex'
export default {
    name: 'showAccount',
    data: () => ({
        dialog: false,
        dialogDelete: false,
        dialogPrint: false,
        dialogSorting: false,
        payDialog:false,
        payExpence:{},
        isPrint: false,
        write_total: '',
        rules: {
            required: [val => (val || '').length > 0 || 'ادخل الحقل '],
        },
        cash: [{
            id: '1',
            name: 'نقد'
        }, {
            id: '0',
            name: 'اجل'
        }],
        complete: [{
            id: 0,
            name: 'غير مكتمل'
        }, {
            id: 1,
            name: 'مكتمل'
        }],
        headers: [{
                text: 'المشروع',
                value: 'project.name',
                align: 'start',
                groupable: false,

            },
            {
                text: 'الفقرة',
                value: 'title',
                align: 'start',
                groupable: false,
            },
            {
                text: 'الدفع',
                value: 'is_cash',
                groupable: false,

            },
            {
                text: 'مكتمل',
                value: 'is_complete',
                groupable: true
            },
            {
                text: 'المتعهد',
                value: 'person.name',
                sortable: false
            },
            {
                text: 'التبويب',
                value: 'expense_type.name',
                groupable: false,

            }, 
            {
                text: 'تاريخ',
                value: 'first_invoice_date',
                groupable: false,

            },  {
                text: 'رقم الوصل',
                value: 'out_bill_number',
                sortable: false,
                groupable: false,
            },
            {
                text: 'الكلي',
                value: 'total',
                groupable: false
            },
          
            {
                 text: 'المدفوع',
                 value: 'paid',
                 sortable: false,
                groupable: false, 
             },
             {
                 text: 'المتبقي',
                 value: 'rest',
                 sortable: false,
                groupable: false,

             },

            {
                text: ' العمليات',
                value: 'operation',
                sortable: false,
                groupable: false
            },
        ],
        headersPrint: [{
                text: 'المشروع',
                value: 'project.name',
                align: 'start',
                sortable: false
            },
            {
                text: 'الفقرة',
                value: 'title',
                sortable: false
            },

            {
                text: 'مكتمل',
                value: 'is_complete',
                sortable: false
            },
            {
                text: 'المتعهد',
                value: 'person.name',
                sortable: false
            },
            {
                text: 'تاريخ',
                value: 'first_invoice_date',
                groupable: false,

            },
            {
                text: 'التبويب',
                value: 'expense_type.name',
                sortable: false
            },
            {
                text: 'مرحلة العمل',
                value: 'construction_category.name',
                sortable: false
            },
            {
                text: 'المبلغ ',
                value: 'total',
                sortable: false
            }, 
             {
                 text: 'المدفوع',
                 value: 'paid',
                 sortable: false
             },
             {
                 text: 'المتبقي',
                 value: 'rest',
                 sortable: false
             },
            {
                text: 'رقم الوصل',
                value: 'out_bill_number',
                sortable: false
            },
        ],
        dialogPrintSet: false,
        
        printingArrayChoies:[{text:"p_title",name:'العنوان'},{ text:"table" , name:'الجدول'},{ text:"footer" , name:'مجموع المبلغ'},{ text:"geter_reco" , name:'توقيع المهندس'}],
        printingArray:[{text:"p_title",name:'العنوان'},{ text:"table" , name:'الجدول'},{ text:"footer" , name:'مجموع المبلغ'},{ text:"geter_reco" , name:'توقيع المهندس'}],
            
        editedIndex: -1,
        editedItem: {
            title: '',
            is_cash: 'null',
            out_bill_number: '',
            is_complete: 'null',
            is_total: false,
            construction_category: {
                id: ''
            },
            project_id: '',
            expense_type: {
                id: ''
            },
            person_id: '',
            person: {
                id: ''
            },
            count: 1,
            unit_price: 0,
        },
        defaultItem: {
            title: '',
            is_cash: 'null',
            is_complete: 'null',
            is_total: false,

            out_bill_number: '',
            from_invoice: '',
            to_invoice: '',
            construction_category: {
                id: ''
            },
            project_id: '',
            expense_type: {
                id: ''
            },
            person_id: '',
            person: {
                id: ''
            },
            count: 0,
            unit_price: 0,
        },
        searchExpenses: {
            is_cash: '',
            is_complete: '',
            out_bill_number: '',
            construction_category_id: '',
            project_id: '',
            from_invoice: '',
            to_invoice: '',
            expense_type_id: '',
            person_id: '',
            user_id: '',
            from: '',
            to: '',
        },
        searchExpensesDefault: {
            is_cash: '',
            is_complete: '',
            out_bill_number: '',
            construction_category_id: '',
            project_id: '',
            expense_type_id: '',
            person_id: '',
            user_id: '',
            from: '',
            to: '',
        }
    }),

    computed: {
        ...mapGetters(['my_safes', 'projects_n', 'users_name', 'expenses', 'expensesReport', 'employees_n', 'constructions', 'contructors_n', 'expensesType', 'units']),
        total() {
            return this.editedItem.count * this.editedItem.unit_price
        }, 
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    mounted() {
        tools.checkPermissions('expense-search')

        this.f_constructors_n();
        this.f_constructions();
        this.f_expensesType();
        this.f_unit();
        this.f_projects_n();
        this.f_users_name();
        this.f_employees_n()
        this.f_my_safes()

    },
    methods: {
        ...mapActions(['f_expenses', 'f_my_safes','payAmonut', 'f_users_name', 'f_employees_n', 'f_projects_n', 'addExpenses', 'addExpensesCash', 'editExpenses', 'deleteExpenses', 'f_unit', 'f_constructors_n', 'f_constructions', 'f_expensesType']),
        openExpense(id) {
            router.push('/expensesDetials/' + id)
        },
        searchEx() {
            this.f_expenses(this.searchExpenses)
            this.dialogSorting = false
        },

        searchDefault() {
            this.searchExpenses = Object.assign({}, this.searchExpensesDefault)
        },
        payAmonutLocal(id){
     
            this.payExpence.id=id
            this.payDialog=true
        },
        payConfirm(){
            console.log(this.payExpence);
            this.payAmonut(this.payExpence)
            this.payDialog=false

        },

        sorting() { 
            this.dialogSorting = true
        },
        print() {
            this.dialogPrintSet = true;
        },
        printOk() {
            this.dialogPrintSet = false;
            this.dialogPrint = true;
            printing(this.printingArray)
            setTimeout(()=>{
              this.dialogPrint = false;
            },200)
        },

        checkPermissions(per) {
            tools.checkPermissions(per)
        },

        deleteItem(item) {
            this.checkPermissions('expense-delete')
            this.editedIndex = this.expenses.indexOf(item)
            this.editedItem.id = item.id
            this.dialogDelete = true
        },
        deleteItemConfirm() { 
            this.editedItem.fetch = this.searchExpenses
            this.toProject=false

            this.deleteExpenses(this.editedItem)
            this.closeDelete()
        },

        exit() {
            this.dialog = false

        },

        close() {
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        // save() { //save dialog add and edit
        //     this.editedItem.construction_category_id = this.editedItem.construction_category.id;
        //     this.editedItem.expense_type_id = this.editedItem.expense_type.id

        //     if (this.editedItem.unit_price)
        //         this.editedItem.total = this.total
        //     else
        //         this.editedItem.total = this.write_total

        //     let params = {}
        //     this.editedItem.fetch = params

        //     if (this.editedIndex > -1) {
        //         //update item    
        //         this.editExpenses(this.editedItem)
        //     } else {
        //         //add new item  
        //         if (this.editedItem.is_cash == 1)
        //             this.addExpensesCash(this.editedItem)
        //         else if (this.editedItem.is_cash == 0)
        //             this.addExpenses(this.editedItem)
        //     }
        //     this.close()
        // },

    },
}
</script>
