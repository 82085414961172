<template>
  <v-container>  
    <FullCalendar/>
  </v-container>
</template>
<script>
import FullCalendar from "../../components/puplic/fullCalendar.vue"; 
export default {
    name: "calculatorPage",
    components: { FullCalendar }
};
</script>
