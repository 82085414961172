import Vue from 'vue'
import VueRouter from 'vue-router' 
import homePage from '../views/home/homePage' 
import calculatorPage from '../views/home/calculatorPage'
import cardsPage from '../views/home/cardsPage'
import loginPage from '../views/home/loginPage'
import homeView  from '../views/home/homeView'
import newProject from '../views/home/newProject'
import addDailyPosition from '../views/home/addDailyPosition'
import accountsPage from '../views/home/accountsPage'
import dailyPosition from '@/views/home/dailyPosition'
import changePassword from '@/views/setting/changePassword'

//accounts
import showAccount from '../views/accounts/showAccount'
import officeAccounts from '../views/accounts/officeAccounts'
import employeeInvoice from '../views/accounts/employeeInvoice'
//cards
import contractorsCard from '../views/cards/contractorsCard'
import employeesCard from '../views/cards/employeesCard'
import unitsCard from '../views/cards/unitsCard'
import contractorTypesCard from '../views/cards/contractorTypesCard'
import employeeTypesCard from '../views/cards/employeeTypesCard'
import customersCard from '../views/cards/customersCard'
import usersCard from '../views/cards/usersCard'
import expensesTypeCard from '../views/cards/expensesTypeCard'
import constructionCard from '../views/cards/constructionCard'
import permissionsCard from '../views/cards/permissionsCard'
import safes from '../views/safe/safes'


//project
import projectPage from '../views/project/projectPage' 
import projectsPage from '../views/project/projectsPage' 
import expensesDetials from '../views/project/expensesDetials'
import editProject from '@/views/project/editProject'

//setting
import mainSetting from '../views/setting/mainSetting'
import constractorContract from '../views/setting/constractorContract'
import projectContract from '../views/setting/projectContract'

//reports
import mainReport from '../views/reports/mainReport'

//guess project
import guessProject from '../views/guessProject/guessProject'
import guessProjects from '../views/guessProject/guessProjects'

//safes
import transactionSafes from '../views/safe/transactionSafes'

// tools  
import tools from '@/plugins/tools'


Vue.use(VueRouter)

const routes = [
   {
     path:'/',
     name:'home',
     component:homeView,
     children:[ 
        {
          path: "/403",
          name: 'errorPermisson',
          component:() => import('../views/setting/errorPermisson'), 
        },
        {
          path:'/',
          name:"main",
          component:homePage
        }, 
        {
          path:'/dailyPosition',
          name:"dailyPosition",
          component:dailyPosition
        }, 
        {
          path:'/projects',
          name:'projects',
          component:projectsPage
       }, 
         {
          path:'/editProject/:id',
          name:'editProject',
          component:editProject
        }, 
         {
          path:'/expenceInfo',
          name:'expenceInfo',
          component:()=>import('../views/accounts/addExpence.vue')
         }, 
        {
          path:'/accounts',
          name:'accountsPage',
          component:accountsPage
        }, 
        {
          path:'/showAccount',
          name:'showAccount',
          component:showAccount
        }, 
        {
          path:'/officeAccounts',
          name:'officeAccounts',
          component:officeAccounts
        }, 
        {
          path:'/employeeInvoice',
          name:'employeeInvoice',
          component:employeeInvoice
        }, 
        {
          path:'/mainSetting',
          name:'mainSetting',
          component:mainSetting
        }, 
        {
          path:'/constractorContract',
          name:'constractorContract',
          component:constractorContract
        }, 
        {
          path:'/projectContract',
          name:'projectContract',
          component:projectContract
        }, 
        
        {
          path:'/mainReport',
          name:'mainReport',
          component:mainReport
        }, 
        
        {
          path:'/expensesDetials/:id',
          name:'expensesDetials',
          component:expensesDetials
        }, 
        {
          path:'/projectPage/:id',
          name:'projectPage',
          component:projectPage
        }, 
        {
          path:'/guessProject/:id',
          name:'guessProject',
          component:guessProject
        }, 
        {
          path:'/guessProjects',
          name:'guessProjects',
          component:guessProjects
        }, 
        {
          path:'/transactionSafes',
          name:'transactionSafes',
          component:transactionSafes
        }, 
        {
          path:'/changePassword',
          name:'changePassword',
          component:changePassword
        }, 
        {
          path:'/cards',
          name:'cardsPage',
          component:cardsPage,
          children:[
            {
              path:"/contractorsCard",
              name:'contractorsCard',
              component:contractorsCard
            }, 
          
            {
              path:"/usersCard",
              name:'usersCard',
              component:usersCard
            },
            {
              path:"/employeesCard",
              name:'employeesCard',
              component:employeesCard
            }, 
            {
              path:"/unitsCards",
              name:'unitsCard',
              component:unitsCard
            }, 
            {
              path:"/constructionCard",
              name:'constructionCard',
              component:constructionCard
            }, {
              path:"/contractorTypesCard",
              name:'contractorTypesCard',
              component:contractorTypesCard
            },
            {
              path:"/employeeTypesCard",
              name:'employeeTypesCard',
              component:employeeTypesCard
            },
            {
              path:"/customersCard",
              name:'customersCard',
              component:customersCard
            },
            
            {
              path:"/expensesTypeCard",
              name:'expensesTypeCard',
              component:expensesTypeCard
            },
            {
              path:"/permissionsCard",
              name:'permissionsCard',
              component:permissionsCard
            },
            {
              path:'/safes',
              name:'safes',
              component:safes
            },  

          ]
        },
        {
          path:'/calc',
          name:'calculatorPage',
          component:calculatorPage
        },
        {
          path:'/newProject',
          name:'newProject',
          component:newProject
         },  
         {
          path:'/addDailyPosition',
          name:'addDailyPosition',
          component:addDailyPosition
         }, 
     ]
   },  
   {
    path:'/login',
    name:'login',
    component:loginPage
   },  
   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {

  if(to.name == 'projects')
    tools.checkPermissions('project-form')
  if(to.name == 'editProject')
    tools.checkPermissions('project-update')
  if(to.name == 'newProject')
    tools.checkPermissions('project-add')
  if(to.name == 'cardsPage')
    tools.checkPermissions('card-form')
  if(to.name == 'usersCard')
    tools.checkPermissions('user-form') 
  if(to.name == 'accountsPage')
    tools.checkPermissions('expense-search') 
  if(to.name == 'mainReport')
    tools.checkPermissions('expense-report') 
  if(to.name == 'safes')
    tools.checkPermissions('safe-form') 
  if(to.name == 'transactionSafes')
    tools.checkPermissions('safe-transaction-form') 
  if(to.name == 'dailyPosition')
    tools.checkPermissions('daily-form') 
  if(to.name == 'addDailyPosition')
    tools.checkPermissions('daily-add') 
  
  


 
  if(to.name == 'guessProjects')
    tools.checkPermissions('guess-form')

  if (to.name !== 'login' && !localStorage.getItem("token") )
   next({ name:'login'})
  else if(to.name == 'login' && localStorage.getItem("token"))
    next({ path:'/'})
  else 
   next()
})



export default router
