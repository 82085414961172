

import router from "@/router"

//get permission of users


const checkPermissions = (name) => { 

    let my_permissions= localStorage.getItem('permissions')
    my_permissions = JSON.parse(my_permissions)
 
    if (!my_permissions.find((element) => element.name == name))
            router.push('/403') 
    }


export default{
    checkPermissions, 
}