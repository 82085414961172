import axios from "axios";

 
    export default function printing(tag){  
        axios
        .get("a/setting/get")
        .then((res) => {
            let setings=res.data.setting  
            setTimeout(function(){  
            
                var newWin=window.open("","","width=900,height=700 ");
                let time=new Date().toLocaleTimeString();
                let date=new Date().toLocaleDateString();
                newWin.document.write(` 
                   <div> 
                   <img  height="100" width="100" style=" display: block;  margin-left: auto; margin-right: auto; "  src="${setings.company_photo}" alt=""> 
                   <h3 style="text-align: center;">${setings.company_name}</h3>
                     <div style="display: flex; justify-content: space-between; margin: 2px"  >
                        <div>
                        <h5 style="text-align: right;margin: 2px" ">العنوان :${setings.company_address} </h5> 
                            <h5 style="text-align: right;margin: 2px" "> ${time} ${date} :تاريخ الطباعة</h5> 
                        </div>
                        <div>
                      
                        </div>
                        <div>
                        <h5 style="text-align: right;margin: 2px" "> ${setings.company_phone} :رقم الهاتف</h5> 
                        <h5 style="text-align: right;margin: 2px" ">بأدارة :${setings.company_boss_name}</h5> 
                        <h5 style="text-align: right;margin: 2px" "> ${setings.company_email} :البريد الالكتروني</h5> 
                        </div>
                    </div> 
                        
                    <hr> 
                  
                `
                                        
                );
    
    
                for (let index = 0; index < tag.length; index++) {
                    var divToPrint=document.getElementById(tag[index].text); 
                    if(divToPrint)
                    newWin.document.write(divToPrint.outerHTML); 
                }
                 
                const styleSheet = document.createElement("style")
                styleSheet.type = "text/css"
                styleSheet.innerText = `
                
                table {
                    direction: rtl;
                    border-collapse: collapse;
                    width: 100%;
                }
                
                table td, table th {
                    text-align: center !important;
                    border: 1px solid #ddd;
                    padding: 5px;
                } 
                .p_center{
                    text-align:center
                }
                .p_right{
                    text-align:right
                }
                .p_left{
                    text-align:left
                }
                .p_box{
                    border:1px solid black;
                    margin:5px;
                    padding:3px
                }
                
                table th {
                    padding-top: 5px;
                    padding-bottom: 5px; 
                }` 
    
    
                newWin.document.head.appendChild(styleSheet)
    
                setTimeout(function(){
                     newWin.print();
                     newWin.close(); 
                     },1000)
               
                
                // 
                
            }, 100);// wait dialog to open
        })
        .catch((err) => { 
            console.log(err  );
        });
       
    }
    
